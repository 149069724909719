/* eslint-disable react/prop-types */
import  { createRef, useEffect, useState } from 'react'
import { Crop } from "lucide-react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import atob from 'atob';
import thumb from '../../assets/thumb.jpg'
/* eslint-disable react/prop-types */
export default function ImageField(props) {
    const {label,name,formik, id="#", url="",setResizerCropper} = props;
    const [preview,setPreview] = useState('');
// crop image start
const [activeCropper , setActiveCropper] = useState(false)
const [activeCropIcon,setActiveCropperIcon] = useState(false)
const [cropData, setCropData] = useState("#");
const cropperRef = createRef();

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

const getCropData = () => {
  if (typeof cropperRef.current?.cropper !== "undefined") {
    setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    let img = dataURLtoFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),'hello.jpg')
   
    formik.setFieldValue("image",img)
    setActiveCropperIcon(true)
   
  }
  setActiveCropper(false);
  setResizerCropper(false)
 
};
// crop image end

    const handleCardThumb = (event) => {
      const file = event.target.files[0];
      setCropData("#")
       setActiveCropperIcon(true)
      if (file) {
      console.log("file type is",file.type)
        
        const reader = new FileReader();
        reader.onloadend = () => {
          const newImages = {...formik.values.image};
          setPreview(reader.result);
          
  
          formik.setFieldValue('image',file)
          console.log("the file is",newImages.url)
        };
        reader.readAsDataURL(file);
      }
    }; 

    useEffect(()=>{
      if(formik.values.image !== null && typeof formik.values.image==="string"){

        setPreview(url+formik.values.image)
      }
      
    },[formik.values.image,url])
  return (
    <div className="pb-3">
     <label className='text-center w-100'>   {label}</label>
                        
     <div  className='position-relative'>
        <input form={id} type="file" id={`thumb`} name="image"  onChange={(event) => handleCardThumb(event)} className="mb-2 d-none" />
        {activeCropIcon  ?
        <div className="position-absolute top-0 end-0 bg-primary p-1 pe-auto"
        onClick={()=>{setActiveCropper(true); setResizerCropper(true);setActiveCropperIcon(false)}}
        >
          <Crop size={30} color="#ffffff"/>
        </div> : null}
        {!activeCropper ?
        <div>
        {cropData ==="#" ?
        <img src={preview ? preview : thumb} className="w-full w-100" alt=" iamge" 
    onClick={() => {
        document.getElementById(`thumb`).click();
    }}/>
    : 
    <img src={cropData} className="w-full w-100" alt=" iamge" 
    onClick={() => {
        document.getElementById(`thumb`).click();
    }}/>
  }
    </div>
    : 
    <div className="pb-4 container" style={{height:400, overflow:"hidden"}}>
    {/* Cropper section start */}
    <Cropper
   ref={cropperRef}
   style={{ height: "100%", width: "100%", objectFit:"cover" }}
   zoomTo={0.5}
   initialAspectRatio={1}
   preview=".img-preview"
   src={preview}
   viewMode={1}
   minCropBoxHeight={10}
   minCropBoxWidth={10}
   background={false}
   responsive={true}
   autoCropArea={1}
   checkOrientation={false}
   guides={true}
 />
  <button className="btn-primary float-right" onClick={getCropData}>
       Crop Now
     </button>
    {/* Cropper section end */}
  </div>
}
        {formik.touched.image && formik.errors.image && (
          <div className=" pb-1 text-[12px] text-red-600  font-imprima">
           {formik.errors.image}
          </div>
       
        )}
        </div>
               {formik.touched[name] && formik.errors[name] && (
          <div className=" text-xs text-danger">
           {formik.errors[name]}
          </div>
       
        )}
    </div>
  )
}
