/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import Banner from '../../Components/Home/Banner'
import ColllectionsCard from '../../Components/Home/ColllectionsCard'
import { getAllCollectionsPublicService } from '../../services/collectionsService'
import { Link } from 'react-router-dom'

const Home = () => {
    const [data,setData]= useState([])
    const [url,setUrl]= useState('')
    const [,setLoader] = useState(false)

    const allCollectionsData= async ()=>{
        setLoader(true)
          const result =  await getAllCollectionsPublicService()
          if(result.categorycardCollectionTitle){
        
            setData(result.categorycardCollectionTitle)
            setLoader(false)
            setUrl(result.imagePath)
          }
          else{
            setLoader(false)
          }
    }
    useEffect(()=>{
       

            allCollectionsData();
        
    },[])
    return (
        <>
            <div className="no-bottom no-top" id="content">
                <div id="top"></div>
                <Banner />
                <section id="section-trending" className="pt40 no-bottom">
                    <div className="container">
                        <div className="row">
                                <h2>Latest Collection</h2>
                                {data?.map((item,index)=>(
                            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <Link to={`/collection/${item.slug}`}>
                                <ColllectionsCard  src={`${url}${item.image}`} name={item.name}/>
                                </Link>
                            </div>
                           ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Home