import { axiosInstance } from "../utils/axiosHelper"


export const addSwapRequest=async(data)=>{
   const formdata = new FormData();
   formdata.append('offered_card_id',data.offered_card_id)
   formdata.append('requested_card_id',data.requested_card_id)
   formdata.append('quantity',data.quantity)

   return await axiosInstance.post('user/swaps',formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const getAllSwapService =async()=>{
   return await axiosInstance.get('/user/order/swap-request')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getSwapByIdService =async(id)=>{
   return await axiosInstance.get(`user/swaps/${id}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

export const changeCardStatus =async(status,id)=>{
   const formdata = new FormData();
   formdata.append('is_active',status);
   formdata.append('id',id);
   return await axiosInstance.post('user/cards/changeStatus',formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

export const deleteUserCard =async(id)=>{
   return await axiosInstance.delete(`user/swaps/${id}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}




export const deleteSwapRequest =async(id)=>{
   return await axiosInstance.get(`user/swaps/${id}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


