import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL: 'https://albumsticker.dev-hi.xyz/api/',

   
})


axiosInstance.interceptors.request.use((res)=>{
    
    if(res){

        res.headers.Authorization="Bearer " +localStorage.getItem('token');
    }
    
    return res;
},(err)=>{
    console.log("intercepter reposne",err.response.data.message)
    if(err.response.data.message==="Unauthenticated"){
    
       
      localStorage.removeItem('token');
     

    }
    throw err
})