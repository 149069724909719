import React, {  useContext, useEffect, useState } from "react";

import { ClipLoader } from "react-spinners";
// import ColllectionsCardUser from "../Home/ColllectionsCardUser";
import Swal from "sweetalert2";
import { deleteSubCollectionUserCardRequest,getAllSubCollectionsbyUserService } from "../../../services/collectionServiceCard";
import { rootContext } from "../../../context/RootContext";
import SubCollectionCard from "./SubCollectionCard";
export default function SubCollectionsTab() {
  const {changRequestData,setisSub,setValues,setShow} = useContext(rootContext)
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [loader,setLoader] = useState(true)
 
  const allCollectionsData = async () => {
    // setLoader(true)
    const result = await getAllSubCollectionsbyUserService();
    console.log("the result is",result)
    if (result) {
      setData(result.subCollectionRequest);
      setLoader(false)
      setUrl(result.imagePath);
    } else {
      setLoader(false)
    }
  };
  

  // convert url image to binary start
  // delete card start
  const openDeleteConfirmModal = async (id) => {
    // alert(row.original.id)

    Swal.fire({
      title: "Do you want to Delete a Application",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoader(true);

        const reuslt = await deleteSubCollectionUserCardRequest(id);
        console.log("the response is",result)
        if (reuslt) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Application has been deleted successfully.",
          });
          setLoader(false);
          allCollectionsData();
        } else {
          // setIsUpdatingUser(false);
          // setIsLoadingUsers(false);
          
          setLoader(false);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  // delete card end

  
  const editCard=(data)=>{
    
    const updatedData = {
      ...data,
      imageUrl: url + data.image, 
      _method:"PUT"
    };
    
    setisSub(true)
    setValues(updatedData)
    setShow(true)
    
   

  }
// change status end
  useEffect(() => {
    allCollectionsData();
  }, []);
  useEffect(() => {
    allCollectionsData();
  }, [changRequestData]);
  return (
    <div>
      {!loader ? (
        <div className="New Collections">
          <h2> Collections</h2>
       
          <div className="row">
            {data?.map((item, index) => (
              <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  {console.log("the log file is",item)}
                  <SubCollectionCard
                    src={`${item?.image ? url+item?.image : null }`}
                   data={item}
                   deleteFnc={openDeleteConfirmModal}
                   editFnc={editCard}
                 
                  />
               
              </div>
            ))}
          </div>
        
        </div>
      ) : (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <ClipLoader loading={loader} color="#333" size={50} />
        </div>
      )}
    </div>
  );
}
