import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";
import { LoaderCircle } from "lucide-react";



import CollectionsTab from "./requectcollectionstab/CollectionsTab";
import {getAllCollectionsTitle } from "../../services/collectionServiceCard";
import { rootContext } from "../../context/RootContext";
import { addCollectionRequestCardService, addSubCollectionRequestCardService } from "../../services/cardService";
import SubCollectionsTab from "./requectcollectionstab/SubCollectionsTab";

const RequestedCollections = () => {
  const {setChangeRequestData,values, setValues,show, setShow,isSub, setisSub,tabIndexChild,setTabIndexChild} = useContext(rootContext)
  
  const [loader, setLoader] = useState(false);
  // const [show, setShow] = useState(false);

  const [collection, setCollection] = useState([]);

  // const [values, setValues] = useState({
  //   collection_name: "",
  //   sub_collection_name: "",
  //   image: null,
  //   imageUrl: null,
  // });
  const onChange = (event) => {
    const { value, name, files } = event.target;
    if (name === "image") {
      const file = files[0];
      setValues({
        ...values,
        image: file,
        imageUrl: URL.createObjectURL(file),
      });
    } else setValues({ ...values, [name]: value });
  };
  const handleClose = () => setShow(false);
  const handleShow = () =>{ 
    
// setTabIndexChild(0)
    setValues({
      
          collection_name: "",
          sub_collection_name: "",
          image: null,
          imageUrl: null,
     
    })
    setShow(true);}


  const allCollectionsData = async () => {
    // setLoader(true)
    const result = await getAllCollectionsTitle();
 
    if (result.categorycardCollectionTitle) {
      setCollection(result.categorycardCollectionTitle);
      setLoader(false)
   
    } else {
      setLoader(false)
    }
  };

  const Addcollection = async () => {
    setLoader(true);
    let url="";
    const formdata = new FormData();
    formdata.append("collection_name", values.collection_name);
    if (typeof values.image !== 'string') {
      formdata.append("image", values.image);
    }
    if('_method' in values){
      url=`user/collectionrequests/${values.id}`
      formdata.append("_method","PUT");
    }
    else{
      url='user/collectionrequests'
    }
 const result =await addCollectionRequestCardService(url,formdata)
 setTabIndexChild(0)
 if(result.collectionRequest){
  setTabIndexChild(0)
 handleClose();
        Swal.fire({
          icon: "success",
          title: result.message,
        });
        let x=Math.random();
        setChangeRequestData(x)
        setValues({
          collection_name: "",
          sub_collection_name: "",
          image: null,
          imageUrl: null,
        });
        allCollectionsData();
        setLoader(false);
 }
 else{
  Swal.fire({
    icon: "error",
    title:result.errors[0],
  });
  setLoader(false);
  
 }
 
  };
  const AddSubcollection = async() => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("collection_name", values.collection_name);
    // formdata.append("image", values.image);
    formdata.append("sub_collection_name", values.sub_collection_name);
    let url ="";
    if (typeof values.image !== 'string') {
      formdata.append("image", values.image);
    }
    if('_method' in values){
      url=`user/subcollectionrequest/${values.id}`
      formdata.append("_method","PUT");
    }
    else{
      url='user/subcollectionrequest'
    }
    const result = await addSubCollectionRequestCardService(url,formdata)
       if(result.subCollectionRequest){
        setTabIndexChild(1)
         handleClose();
         Swal.fire({
           icon: "success",
           title: result.message,
          });
          const x = Math.random()
          setChangeRequestData(x)
          setValues({
            collection_name: "",
            sub_collection_name: "",
            image: null,
            imageUrlUrl: null,
          });
          setLoader(false);
          
          
        }
        else{
          Swal.fire({
            icon: "error",
            title:result.errors[0],
          });
        }
  };
  useEffect(() => {
    allCollectionsData()
  }, []);
  return (
    <>
      <h1 className="pt-3 text-black">Requested Collections</h1>

      <div className="position-relative my-3">
        <div className="text-end">
          <button className="btn btn-primary" onClick={handleShow}>
            Request for a Collections
          </button>
        </div>
      <div className="container">
      <div className="container">
          <Tabs
            defaultIndex={0}
            selectedIndex={tabIndexChild}
         
            onSelect={(index) => setTabIndexChild(index)}
      
          >
            <TabList>
              <Tab> Collection</Tab>
              <Tab>Sub Collection</Tab>

            </TabList>

            <TabPanel>
            <CollectionsTab />
            </TabPanel>
            <TabPanel>
           <SubCollectionsTab />
            </TabPanel>

          
          </Tabs>
        </div>
      </div>
        
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Request for a {isSub ? "Sub-Collection" : "Collection"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-end mb-3">
              <button
                className="btn btn-primary"
                onClick={() => setisSub(!isSub)}
              >
                Request for a {!isSub ? "Sub-Collection" : "Collection"}
              </button>
            </div>
            {!isSub ? (
              <input
                placeholder="Enter New Collection Name"
                className="form-control mb-3"
                onChange={onChange}
                name="collection_name"
                value={values.collection_name}
              />
            ) : (
              <>
                <select
                  className="form-control mb-3"
                  name="collection_name"
                  value={values.collection_name}
                  onChange={onChange}
                >
                  <option selected hidden>
                    Select Collection Name...
                  </option>
                  {collection.length > 0 &&
                    collection.map((data) => (
                      <option value={data.name}>{data.name}</option>
                    ))}
                </select>
                <input
                  name="sub_collection_name"
                  value={values.sub_collection_name}
                  onChange={onChange}
                  placeholder="Enter New Sub-Collection Name"
                  className="form-control"
                />
              </>
            )}
            <input
              className="form-control mb-3"
              onChange={onChange}
              type="file"
              name="image"
            />
            {console.log("the values are",values)}
            {values.image && (
              <img src={values.imageUrl} alt="imge" className="w-100 rounded" />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={loader}
              onClick={!isSub ? Addcollection : AddSubcollection}
            >
              {loader ? "Requesting..." : "Request"}{" "}
              {loader && <LoaderCircle />}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default RequestedCollections;
