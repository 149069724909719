import React from 'react'

const Banners = ({ name }) => {
    return (
        <>
            <div class="no-bottom no-top" id="content">
                <div id="top"></div>
                <section id="" class="jarallax text-light d-flex justify-content-center align-items-center" style={{ backgroundImage: 'url("/assets/images/background/subheader.jpg")', backgroundSize: "cover" }} >
                    <div className=''>
                        <h1>{name}</h1>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Banners