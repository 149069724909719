import { axiosInstance } from "../utils/axiosHelper"

export const getSubecriptions =async()=>{
   return await axiosInstance.get('user/subscriptions')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const addSubecriptions =async(id,upgrade)=>{
   const formdata = new FormData();
   formdata.append('plan_id',id);
   if(upgrade !==0){
      formdata.append('protection_price',upgrade);
   }
   return await axiosInstance.post('user/subscriptions',formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const addFreeSubecriptions =async(id,)=>{
   const formdata = new FormData();
   formdata.append('plan_id',id);
 
   return await axiosInstance.post('user/subscriptions/free',formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const sucessSubecriptions =async(id,token,payerId)=>{

   return await axiosInstance.get(`user/subscriptions/paypal/payment/success/${id}?token=${token}&PayerID=${payerId}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const addProtectionPlan =async(price)=>{
   const formdata = new FormData();
   formdata.append('protection_price',price);
  
   return await axiosInstance.post('user/protections',formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
