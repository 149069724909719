/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";

export default function Faq() {
  const [loader] = useState(false);
  return (
    <div className="pb-3">
      {!loader ? (
        <div className="container">
          <h1 className="pt-3 text-black">Frequently Asked Questions</h1>
          <div className="text-black">
            {/* heading start */}
            <div className="py-2">
              <h5>1. What is the difference between plans?</h5>
              <p className="fw-bold pm-0">We have 3 main Plans:</p>
              <p class="fw-normal ">
                Free Plan - You can create an account and explore all our
                collections, no credit card needed. You can selct the
                collections and stickers you have and print out a list so you
                can track your progress. The swap and selling options are
                disabled. Pro Plan - Swapping option is enabled which allow you
                to get in contact with other users to swap stickers. You can
                also get invitations to participate in some of our events
                promoted by Estampers.
              </p>
              <p class="fw-normal ">
                Goat Plan - You will be able not only to swap but to sell again.
                Sometines you complete your albums and still have a lot of
                stickers on ypur hands, well we now that and for that reasons we
                have enabled a selling option so you can recover money for your
                next collection by helping others to complete their collections.
              </p>
            </div>
            {/* heading end */}
            {/* heading start */}
            <div className="py-2">
              <h5>2. What is the Security Plan?</h5>

              <p class="fw-normal ">
                You don't want to share your addres with other users, we got you
                covered. Upgrade your plan to enable the security Plan which
                allows you to send the stickers to Estampers main office and we
                take care of your delivery by forwarding the stickers to your
                counterparty, keeping your data private.
              </p>
            </div>
            {/* heading end */}
            {/* heading start */}
            <div className="py-2">
              <h5>3. How does it work?</h5>

              <p class="fw-normal ">
                Estampers is a stickers swapping platform to connect users to
                complete their collections. The process is simple{" "}
              </p>
              <ol className="font-normal" type="a">
                <li>
                  {" "}
                  you create your account and select the plan of your
                  preference.{" "}
                </li>
                <li>
                  {" "}
                  Select from pur site all the collections you are interest in.
                  These will apear in "My collections".
                </li>
                <li>
                  {" "}
                  Select all the stickers you have in your collection, this is
                  for your own knowledge so you can keep track of your progress.
                  You can print out the list of stickers so you can also make
                  swaps offline.
                </li>
                <li>
                  {" "}
                  Select the stickers you need, and the stickers you have to
                  swap,these will indicate our system all the stickers you are
                  looking for to get the matches with those users that have the
                  stickers you need and viceversa. The system will show you the
                  swapping results showing their proximity and their delivery
                  options so you can choose the one that is better for you.
                </li>
                <li>
                  {" "}
                  Once you select the best option you can contact the other user
                  to reach an Initial agreement, confirm the delivery method or
                  any other question you may have.
                </li>
                <li>
                  {" "}
                  Once accepted the initial agreement a Deal is created, you
                  must go to the Make a deal section, look for the deal you want
                  and you will be taken to our authenticity process. The process
                  is optional (always that both users agree so), but we strongly
                  recommend to use this tool, this will allow you to upload an
                  actual photo of the stickers so each user send the proof that
                  they have the sticker and the quality as well.
                </li>
                <li>
                  {" "}
                  Both users can review and accept or reject the deal. If
                  rejected you can leave your feedback to help other users. If
                  accepted depending of your plan you will prompt to send the
                  stickers to your counterparty or you will receive indications
                  to send the stickers to Estampers PO Box.
                </li>
              </ol>
            </div>
            {/* heading end */}
            {/* heading start */}
            <div className="py-2">
              <h5>4. How can I sell?</h5>

              <p class="fw-normal ">
                The selling feature is only enabled for Goat users. When you are
                a Goat User you will have an extra column to indicate which
                stickers are available for sale. When a Goat user request a swap
                in their results page the system will show not only the swap
                results but the sale results, so even if there are not other
                users to swap with, they would always have a second option to
                complete their collection by purchasing the missing stickers.
                When the Security Plan is enabled the payment is sent to
                Estampers and when the users confirm reception of the cards then
                the funds are released to the user. For regular plans the users
                agrees the payment according to their own references this is a
                pier to pier transaction and Estampers is not involved.
              </p>
            </div>
            {/* heading end */}
          </div>
        </div>
      ) : (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <ClipLoader loading={loader} color="#333" size={50} />
        </div>
      )}
    </div>
  );
}
