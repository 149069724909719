/* eslint-disable jsx-a11y/anchor-is-valid */
import  { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import * as yup from 'yup';
//import { ClipLoader } from 'react-spinners';
import {validatar} from '../../utils/validation'
import { loginUserService } from '../../services/authService';
import PasswordField from '../Form/PasswordField';
import EmailField from '../Form/EmailField';
const LoginForm = () => {
    const [ ,setLoader ] = useState(false);

    const navigate = useNavigate();
    const formik = useFormik({
       initialValues:{
             
        email:"",
        password:""
      
       },
       validationSchema: yup.object({
        email: validatar("Email is required"),
        password: validatar("Password is required"),
       
       }),
       onSubmit:async(values)=>{
        setLoader(true);
        const result = await loginUserService(values);
       
        setLoader(true)
        if(result.user){
          localStorage.setItem('token',result.accessToken);
          localStorage.setItem('user',JSON.stringify(result.user));
       
          setLoader(false)
          navigate('/collection')
  
        }
        else{
          setLoader(false)
          Swal.fire({
                 icon:'error',
                 title:"Account Registration",
                 text:result.message,
                 showConfirmButton: true,
                 timer: 15000
               })
          
        }
        // localStorage.setItem('token',result.accessToken)
        // navigate('/')
       }
    })
    return (
        <>
            <form name="contactForm" id='contact_form' class="form-border py-5"  onSubmit={formik.handleSubmit}>
                <h3>Login to your account</h3>

                <div class="field-set">
                <EmailField label="Email" name="email" placeholder="Enter your Email" formik={formik}/>
                </div>


                <div class="field-set">
                <PasswordField label="Password" name="password" placeholder="Enter your Password" formik={formik}/>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <p class='text-dark'> <Link to='/forgot'>Forgot Password</Link></p>
                    <p class='text-dark'>You don't have an account. <Link to='/register'>Register</Link></p>
                </div>

                <div className=''>
                    <input type='submit' id='send_message' value='Login' class="btn btn-main color-2 w-100" />
                </div>
                <div class="spacer-single"></div>

               


            </form>
        </>
    )
}

export default LoginForm