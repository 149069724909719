import React from 'react'
import Banners from '../../Components/Banner/Banners'
import CartDetail from '../../Components/Collection/CartDetail'

const CartCheckout = () => {
    return (
        <>
            <Banners name="Checkout Page" />
            <CartDetail />
        </>
    )
}

export default CartCheckout