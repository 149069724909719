/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import  { useEffect, useState } from 'react'
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import PasswordField from '../Form/PasswordField';
import { validatar } from '../../utils/validation';
import { ResetPasswordService } from '../../services/authService';
import { useNavigate, useParams } from 'react-router-dom';
const ResetForm = () => {
    const [ ,setLoader ] = useState(false);
    const {id}=useParams();
    const navigate = useNavigate();
    const formik = useFormik({
       initialValues:{
        token:"",
        password:"",
        confirm_password:""
      
       },
       validationSchema: yup.object({
        password: validatar("Email is required"),
        confirm_password: validatar("Password is required"),
       
       }),
       onSubmit:async(values)=>{
        setLoader(true);
        const result = await ResetPasswordService(values);
       
        setLoader(true)
        if(result.status){
         
          Swal.fire({
            icon:'success',
            title:"Password",
            text:result.message,
            showConfirmButton: true,
            timer: 15000
          })
          setLoader(false)
          navigate('/login')
  
        }
        else{
          setLoader(false)
          Swal.fire({
                 icon:'error',
                 title:"Password",
                 text:result.errors[0],
                 showConfirmButton: true,
                 timer: 15000
               })
          
        }
        // localStorage.setItem('token',result.accessToken)
        // navigate('/')
       }
    })
    useEffect(()=>{
        formik.setFieldValue('token',id)
    },[])
    return (
        <>
            <form name="contactForm" id='contact_form' class="form-border py-5" onSubmit={formik.handleSubmit}>
                <h3>Reset Password</h3>

                <div class="field-set">
                <PasswordField label="Password" name="password" placeholder="Enter your Password" formik={formik}/>
                </div>
                <div class="field-set">
                    
                    <PasswordField label="Re-enter Password" name="confirm_password" placeholder="Enter your Confirm Password" formik={formik}/>
                   
                </div>
                <div className='pt-2'>
                    <input type='submit' id='send_message' value='Reset' class="btn btn-main color-2 w-100" />
                </div>
                <div class="spacer-single"></div>

                

            </form>
        </>
    )
}

export default ResetForm