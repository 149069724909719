import React from 'react'

export default function ContactForm() {
  return (
    <>
     <div className="my-5">
        <div className="row ">
          <div className="col-lg-12">
            <h3 className="mb-3">Contact Us</h3>
            <form>
              <div className="row g-3">
                <div className="col-md-12">
                  <label htmlFor="your-name" className="form-label">Your Name</label>
                  <input type="text" className="form-control" id="your-name" name="your-name" required />
                </div>
                
                <div className="col-md-12">
                  <label htmlFor="your-email" className="form-label">Your Email</label>
                  <input type="email" className="form-control" id="your-email" name="your-email" required />
                </div>
                <div className="col-md-12">
                  <label htmlFor="your-surname" className="form-label">Reason</label>
                  <select  className="form-control" id="your-surname" name="your-surname" >
                    <option value="">Plan </option>
                    <option value="">Authentication Issue</option>
                    <option value="">Swapping Issue</option>
                    </select>
                </div>
                <div className="col-md-12">
                  <label htmlFor="your-subject" className="form-label">Your Subject</label>
                  <input type="text" className="form-control" id="your-subject" name="your-subject" />
                </div>
                <div className="col-12">
                  <label htmlFor="your-message" className="form-label">Your Message</label>
                  <textarea className="form-control" id="your-message" name="your-message" rows={5} required defaultValue={""} />
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6">
                      <button data-res="<?php echo $sum; ?>" type="submit" className="btn btn-dark w-100 fw-bold">Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
