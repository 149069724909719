import { axiosInstance } from "../utils/axiosHelper"

export const getAllSubCollectionsService =async(slug)=>{
   return await axiosInstance.get(`cardCollectionSubTitles?cardCollectionTitleSlug=${slug}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
