/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
// import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link, useParams } from 'react-router-dom'
import { VerifyuSignupService } from '../../../services/authService';

const Verify = () => {
    const [Loader ,setLoader ] = useState(true);
    const [data,setData]= useState("")
    const {id} = useParams();
   
    const VerifyAccount = async(token)=>{
       
        const result = await VerifyuSignupService(token)
        console.log("the data is",result)
        if(result.status){
          setData(result.message)
           
           
          setLoader(false)
          }
          else{
            setLoader(false)
            console.log(result.errors[0])
            setData(result.errors[0])
            
          }
    }
    useEffect(()=>{
       
            VerifyAccount(id)

        
    },[])
    return (
        <>
       <div class="no-bottom no-top" id="content">
                <div id="top"></div>
                <section  class="jarallax text-light d-flex justify-content-center align-items-center" style={{ backgroundImage: 'url("/assets/images/background/subheader.jpg")', backgroundSize: "cover", height:'100vh'}} >
                    <div className=''>
                        {!Loader ?
                        <div className='text-center'>
                        <h1>{data}</h1>
                        <Link to="/login" className="bbtn btn-main color-2 w-100 py-4">Login</Link>
                        </div>


                        :
                        <ClipLoader size={120} color="#fff" />
}
                    </div>
                </section>
            </div>
        </>
    )
}

export default Verify