import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./Layout/Index";
import Home from "./Pages/Home/Home";
import Collection from "./Pages/Collection/Collection";
import Single from "./Pages/Collection/Single";
import Detail from "./Pages/Collection/Detail";
import SwapsPage from "./Pages/Swaps/SwapsPage";
import AboutPage from "./Pages/About/AboutPage";
import Login from "./Pages/Auth/Login/Login";
import Register from "./Pages/Auth/Register/Register";
import Forgot from "./Pages/Auth/Forgot/Forgot";
import Reset from "./Pages/Auth/Reset/Reset";
import Verify from "./Pages/Auth/Login/Verify";
import ChangePassword from "./Pages/Auth/Reset/ChangePassword";
import MyAccount from "./Pages/Account/MyAccount";
import CartCheckout from "./Pages/Cart/CartCheckout";
import SwapCard from "./Pages/Swaps/SwapCard";
import SwapableCard from "./Pages/Swaps/SwapableCard";
import Plan from "./Pages/Account/Plan";
import Sucess from "./Pages/Subscriptions/Success";
import SuccessOrder from "./Pages/Cart/SuccessOrder";
import SuccessProtection from "./Pages/Subscriptions/SuccessProtection";
import FaqPage from "./Pages/Faq/FaqPage";
import FairPage from "./Pages/Faq/FaqPage";


function App() {
  return (
    <>
      <BrowserRouter>
        <Index>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/collection" element={<Collection />} />
            <Route exact path="/collection/:name" element={<Single />} />
            <Route exact path="/detail/:id" element={<Detail />} />
            <Route exact path="/swaps" element={<SwapsPage />} />
            <Route exact path="/swaps-card" element={<SwapCard />} />
            <Route exact path="/select-swaps-card" element={<SwapableCard />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgot" element={<Forgot />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/verify/:id" element={<Verify />} />
            <Route exact path="/forget/:id" element={<ChangePassword />} />
            <Route exact path="/account" element={<MyAccount />} />
            <Route exact path="/plan" element={<Plan />} />
            <Route exact path="/faq" element={<FaqPage />} />
            <Route exact path="/fair" element={<FairPage />} />
            <Route exact path="/checkout" element={<CartCheckout />} />
            <Route exact path="/subscribtion/success/:id" element={<Sucess />} />
            <Route exact path="/order/success/:id" element={<SuccessOrder />} />
            <Route exact path="/protection/success/:id" element={<SuccessProtection />} />
          </Routes>
        </Index>
      </BrowserRouter>
    </>
  );
}

export default App;
