/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useState } from "react";

import { MoonLoader } from "react-spinners";
// import { rootContext } from "../../context/RootContext";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import { FaCartPlus } from "react-icons/fa";
import useCart from "../../hooks/useCart";
import { useFormik } from "formik";
import * as yup from 'yup';
import { validatar } from "../../utils/validation";

import TextField from "../Form/TextField";
import { addOrderService } from "../../services/orderService";
const CartDetail = () => {


  const [loader,setLoader ] = useState(false);
  const {cartItems} = useCart();
  // eslint-disable-next-line no-unused-vars
  const formik = useFormik({
    initialValues:{
          
      name:"",
      email:"",
      phone_number:"",
      address:"",
      address2:"",
      city:"",
      state:"",
      zipcode:"",
    },
    validationSchema: yup.object({
      name:validatar("Name is required"),
      email:validatar("Email is required"),
      phone_number:validatar("Phone Number is required"),
      address: validatar("Address is required"),
      address2: validatar("Second Address is required"),
      city: validatar("City is required"),
      state:validatar("State is required"),
      zipcode:validatar("Zip Code is required"),
    
    
    }),
    onSubmit:async(values)=>{
       setLoader(true);
       const result = await addOrderService(values);
      console.log(result);
      if(result.link){
        window.location.href = result.link
      }
     
     // localStorage.setItem('token',result.accessToken)
     // navigate('/')
    }
 })

  return (
    <>
      {!loader ? (
      // page start
      <div className="container pt-4">
      <div className="row">
        {/* carts item start */}
        <div className="col-md-4 order-md-2 mb-4">
          <h4 className="d-flex justify-content-between align-items-center mb-3 text-dark">
            <span className="text-muted">Your cart</span>
            <span className="badge badge-secondary bg-primary badge-pill">{cartItems.length}</span>
          </h4>

          <ul className="list-group mb-3">
            {cartItems.map((item,index)=>(
 <li key={index} className="list-group-item d-flex  align-items-center justify-content-between lh-condensed">
  <span>
    <img width="75px" src="https://gotneedswap.co.uk/assets/products/mag24/gns-maeg24-ic-coming-soon.png" className="img-fluid" alt="images" />
  </span>
 <div>
   <h6 className="my-0">{item.card.name}</h6>
   
 </div>
 <span className="">€{item.price} x {item.qty} = €{item.price*item.qty}</span>
</li>
            ))}
           
         
          </ul>
          <div className="card p-2">
            <div className="text-center">
               Total €{cartItems.reduce((accumulator, item) => {
    return accumulator + (item.price * item.qty)
}, 0)}            </div>
          </div>
        </div>
        {/* carts item end */}
        {/* form start */}
        <div className="col-md-8 order-md-1">
          <h4 className="mb-3 text-black text-center">Billing address</h4>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
  

            <div class="col-md-6">
                <TextField
                    label="Name"
                    name="name"
                    placeholder="Enter Name"
                    formik={formik}
                    max={400}
                  />
                </div>
            <div class="col-md-6">
                <TextField
                    label="Email"
                    name="email"
                    placeholder="Enter Email"
                    formik={formik}
                    max={400}
                  />
                </div>
            <div class="col-md-6">
                <TextField
                    label="Phone Number"
                    name="phone_number"
                    placeholder="Enter Phone Number"
                    formik={formik}
                    max={400}
                  />
                </div>
    
            <div class="col-md-6">
                <TextField
                    label="City"
                    name="city"
                    placeholder="Enter City"
                    formik={formik}
                    max={400}
                  />
                </div>
            <div class="col-md-6">
                <TextField
                    label="State"
                    name="state"
                    placeholder="Enter State"
                    formik={formik}
                    max={400}
                  />
                </div>
            <div class="col-md-6">
                <TextField
                    label="Zip Code"
                    name="zipcode"
                    placeholder="Enter Zip Code"
                    formik={formik}
                    max={400}
                  />
                </div>
                <div class="col-md-12">
                <TextField
                    label="Address"
                    name="address"
                    placeholder="Enter Address"
                    formik={formik}
                    max={400}
                  />
                </div>
            <div class="col-md-12">
                <TextField
                    label="Second Address"
                    name="address2"
                    placeholder="Enter Second Address"
                    formik={formik}
                    max={400}
                  />
                </div>
                <div className="col-md-12 text-center py-3">
                  <button className="btn btn-primary">Order Now</button>
                </div>

            </div>
          </form>
         
        </div>
        {/* form end */}
      </div>
    
    </div>
      // page end
      ) : (
        <div className="d-flex  justify-content-center align-items-center vh-100">
          <MoonLoader size={90} loading={loader} color="#333" />
        </div>
      )}
     {/*  */}
  
    </>
  );
};

export default CartDetail;
