import React from 'react'
import Banners from '../../../Components/Banner/Banners'
import RegisterForm from '../../../Components/Auth/RegisterForm'

const Register = () => {
    return (
        <>
            <Banners name='Register' />

            <div class="container py-5">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <h3>Don't have an account? Register now.</h3>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

                        <div class="spacer-10"></div>
                        <RegisterForm />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register