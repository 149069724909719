/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const useSwapItem = () => {
  const [swapItemData, setSwapItemData] = useState([]);

  useEffect(() => {
    // Retrieve cart items from local storage on component mount or update
    const storedCartItems = localStorage.getItem('swapItemData');
    const parsedCartItems = storedCartItems ? JSON.parse(storedCartItems) : [];
    setSwapItemData(parsedCartItems);
  }, []);

  const addToSwapCart = (newItem) => {
    localStorage.setItem('swapItemData', JSON.stringify([newItem]));
    console.log(newItem);
    setSwapItemData([newItem])


    return true;
 
  };

  const removeFromSwapCart = (itemId) => {
    setSwapItemData((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const updateSwapCartItemQuantity = (itemId, newQuantity) => {
    setSwapItemData((prevItems) => {
      const updatedItems = [...prevItems];
      const itemIndex = updatedItems.findIndex((item) => item.id === itemId);
      if (itemIndex !== -1) {
        updatedItems[itemIndex].quantity = newQuantity;
      }
      return updatedItems;
    });
  };

  // Helper function to persist cart items to local storage
  const persistCart = () => {
    if(swapItemData.length>0){

        localStorage.setItem('swapItemData', JSON.stringify(swapItemData));
    }
  };

  // Call persistCart after any cart item changes for persistence
  useEffect(() => {
    persistCart();
  }, [swapItemData]);

  return { swapItemData, addToSwapCart, removeFromSwapCart, updateSwapCartItemQuantity };
};

export default useSwapItem;