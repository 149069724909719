import React from 'react'
import Banners from '../../Components/Banner/Banners'
import DetailCollection from '../../Components/Collection/DetailCollection'

const Detail = () => {
    return (
        <>
            <Banners name="Detail" />
            <DetailCollection />
        </>
    )
}

export default Detail