import React, { useContext, useEffect, useState } from "react";
import { changeCardStatus, deleteUserCard, getAllCardsService } from "../../services/cardService";
import { ClipLoader } from "react-spinners";
import ColllectionsCardUser from "../Home/ColllectionsCardUser";
import Swal from "sweetalert2";
import { rootContext } from "../../context/RootContext";
import AvailableCollections from "../Collection/AvailableCollections";
import useSubscription from "../../hooks/useSubscription";
import AvailableSubCollections from "../Collection/AvailableSubCollections";
export default function MyCards() {
  const {setSelectedTabIndex,setUpdateCardData,setGlobalUrl} = useContext(rootContext)
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [loader,setLoader] = useState(true)
  const {isSubscribed} = useSubscription();
  const allCollectionsData = async () => {
    // setLoader(true)
    const result = await getAllCardsService();

    if (result.card) {
      setData(result.card);
      setLoader(false)
      setUrl(result.imagePath);
    } else {
      setLoader(false)
    }
  };

  // delete card start
const openDeleteConfirmModal = async (id) => {
  // alert(row.original.id)


  Swal.fire({
    title: "Do you want to Delete a Application",
    showCancelButton: true,
    confirmButtonText: "Delete",
  }).then(async (result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      setLoader(true)
      
      const reuslt = await deleteUserCard(id);
      if (reuslt.cardCollectionTitle) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Application has been deleted successfully.",
        });
        setLoader(false)
      } else {
        // setIsUpdatingUser(false);
        // setIsLoadingUsers(false);
        allCollectionsData();
          setLoader(false)
      }
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  });
};
// delete card end
// change status start
const handleStatusCollection = async ( id,status) => {
  console.log("the status is",status)
  const statusValue =  status === 0 ? 1 : 0;
  setLoader(true);
  const result = await changeCardStatus(statusValue,id);
  console.log(result.card);
  if (result.card) {
    allCollectionsData();
    setSelectedTabIndex(1)
    setLoader(false);
     
    
    } else {
        setLoader(false);
       
    }
  };
  const editCard=(data)=>{
    setUpdateCardData(data)
    setSelectedTabIndex(3)
    setGlobalUrl(url)
   

  }
// change status end
  useEffect(() => {
    allCollectionsData();
  }, []);
  return (
    <div>
      {!loader ? (
        <div className="New Collections">
          <h2> Collections</h2>
          {isSubscribed ===null ? <p>- You have not subscribe to any collection. When you subscribe to a collection it will be displayed here.
            </p> : null}
          <div className="row">
            {data?.map((item, index) => (
              <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  {console.log("the log file is",item)}
                  <ColllectionsCardUser
                    src={`${item?.card_images.length >0 ?url+item?.card_images[0]?.image : null }`}
                   data={item}
                   statusFnc={handleStatusCollection}
                   deleteFnc={openDeleteConfirmModal}
                   editFnc={editCard}
                   saleStatus={item.is_for_sale === 0 ? "Swap" : "Sale"}
                  />
               
              </div>
            ))}
          </div>
          <div className="pt-4">
          <AvailableCollections />
          </div>
          
          <div className="pt-4">
          <AvailableSubCollections />
          </div>
          
        </div>
      ) : (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <ClipLoader loading={loader} color="#333" size={50} />
        </div>
      )}
    </div>
  );
}
