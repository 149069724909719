/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect } from 'react'
import Banners from '../../Components/Banner/Banners'
import { useNavigate } from 'react-router-dom'
import ProtectionPlanSuccess from '../../Components/Plans/ProtectionPlanSuccess'

const SuccessProtection = () => {
   

     const navigate = useNavigate();
    useEffect(()=>{
     if(!localStorage.getItem('token')) {
        navigate('/login')
     }
    },[])
    return (
        <>

            <Banners name="Protection Plan Upgrade"/>
        
            <ProtectionPlanSuccess/>


        </>
    )
}

export default SuccessProtection