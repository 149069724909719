/* eslint-disable react/prop-types */
export default function NumberField(props) {
    const {label,name,formik,placeholder,disabled=false,max=100} = props;
 
  return (
    <div className="pb-3">
     <label>   {label}</label>
                     
            <input
              type="number"
              name={name}
              value={formik.values[name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control mb-0"
              placeholder={placeholder}
             disabled={disabled}
             min={0}
             max={max}
            />
           
               {formik.touched[name] && formik.errors[name] && (
          <div className=" text-xs text-danger">
           {formik.errors[name]}
          </div>
       
        )}
    </div>
  )
}
