import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
import "./style.css";
// import required modules
import { Navigation } from 'swiper/modules';

const HomeSlider = () => {
    return (
        <>
            <Swiper className="mySwiper" navigation={true} modules={[Navigation]}>
                <SwiperSlide>
                    <div className="nft_pic mod-b br-15">

                        <span className="nft_pic_info">
                            <span className="nft_pic_title">Blue Nomad</span>
                            <span className="nft_pic_by">Mamie Barnett</span>
                        </span>

                        <div className="nft_pic_wrap">
                            <img src="/assets/images/carousel/crs-12.jpg" className="lazy img-fluid" alt="" />
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="nft_pic mod-b br-15">

                        <span className="nft_pic_info">
                            <span className="nft_pic_title">Cytotoxin</span>
                            <span className="nft_pic_by">Monica Lucas</span>
                        </span>

                        <div className="nft_pic_wrap">
                            <img src="/assets/images/carousel/crs-13.jpg" className="lazy img-fluid" alt="" />
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="nft_pic mod-b br-15">

                        <span className="nft_pic_info">
                            <span className="nft_pic_title">Diminishable</span>
                            <span className="nft_pic_by">Nicholas Daniels</span>
                        </span>

                        <div className="nft_pic_wrap">
                            <img src="/assets/images/carousel/crs-16.jpg" className="lazy img-fluid" alt="" />
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="nft_pic mod-b br-15">

                        <span className="nft_pic_info">
                            <span className="nft_pic_title">Manganite</span>
                            <span className="nft_pic_by">Fred Ryan</span>
                        </span>

                        <div className="nft_pic_wrap">
                            <img src="/assets/images/carousel/crs-17.jpg" className="lazy img-fluid" alt="" />
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="nft_pic mod-b br-15">

                        <span className="nft_pic_info">
                            <span className="nft_pic_title">Elutriator</span>
                            <span className="nft_pic_by">Lori Hart</span>
                        </span>

                        <div className="nft_pic_wrap">
                            <img src="/assets/images/carousel/crs-15.jpg" className="lazy img-fluid" alt="" />
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide> <div className="nft_pic mod-b br-15">

                    <span className="nft_pic_info">
                        <span className="nft_pic_title">Forest Women</span>
                        <span className="nft_pic_by">Fred Ryan</span>
                    </span>

                    <div className="nft_pic_wrap">
                        <img src="/assets/images/carousel/crs-14.jpg" className="lazy img-fluid" alt="" />
                    </div>

                </div>
                </SwiperSlide>
            </Swiper>

        </>
    )
}

export default HomeSlider