/* eslint-disable react/jsx-no-target-blank */

import React from "react";
import { FaRegEdit, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import noImage from "../../assets/images/no-image.png";
const ColllectionsCardUser = ({ src, data, statusFnc, deleteFnc, editFnc,saleStatus }) => {
  return (
    <>
      <div class="position-relative">
     
        <div className="actions-btn">
          <ReactTooltip
            anchorSelect={`#edit${data.id}`}
            place="left"
            content="Edit"
          />
          <ReactTooltip
            anchorSelect={`#status${data.id}`}
            place="left"
            content={data.is_active === 0 ? "Inactive" : "Active"}
          />
          <ReactTooltip
            anchorSelect={`#delete${data.id}`}
            place="left"
            content="Delete"
          />
          <div className="icon-div tw-cursor-pointer" id={`edit${data.id}`} onClick={() => editFnc(data)}>
            <FaRegEdit size={25} color="#ffffff" />
          </div>
          <div
            className="icon-div tw-cursor-pointer"
            id={`delete${data.id}`}
            onClick={() => deleteFnc(data.id)}
          >
            <MdOutlineDelete size={25} color="#ffffff" />
          </div>
          <div
            className="icon-div tw-cursor-pointer"
            id={`status${data.id}`}
            onClick={() => statusFnc(data.id, data.is_active)}
          >
            {data.is_active ? (
              <FaRegEye size={25} color="#ffffff" />
            ) : (
              <FaRegEyeSlash size={25} color="#ffffff" />
            )}
          </div>
        </div>
        <Link to={`/collection/${data.slug}`}>
          <div class="nft__item position-relative">
          
            <div class="nft__item_wrap">
              <div class="d-placeholder"></div>
              <div className="position-absolute  card-sale">
          <span className="bg-primary rounded p-1 card-badge">{saleStatus}</span>
        </div>  
              <img src={src === "null" ? noImage : src} class="lazy" alt="" />
            </div>
            <div class="nft__item_info">
              <h2 className="text-center w-100">{data?.name}</h2>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ColllectionsCardUser;
