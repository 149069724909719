/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CartsItemsModal from "../../Components/cart/CartsItemsModal";
import { FaCartPlus } from "react-icons/fa";
import { rootContext } from "../../context/RootContext";

const Navbar = () => {
  const [isTrue, setIsTrue] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [cartItems, setCartItems] = useState([]); // State to manage cart items

  const navigate = useNavigate();
  const { setIsCartModal, cartItemsLength, setCartItemsLength } = useContext(rootContext);

  // Check for login status
  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  // Update cart items length when localStorage changes
  useEffect(() => {
    const updateCartItems = () => {
      const cart = JSON.parse(localStorage.getItem("cartItems")) || [];
      setCartItems(cart);
      setCartItemsLength(cart.length); // Update length in context
    };

    // Initially load cart items
    updateCartItems();

    // Listen for changes in localStorage
    window.addEventListener("storage", updateCartItems);

    // Update cart when the user manually modifies the localStorage (within same tab)
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments);
      if (key === "cartItems") {
        updateCartItems();
      }
    };

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("storage", updateCartItems);
      localStorage.setItem = originalSetItem; // Restore original setItem behavior
    };
  }, [setCartItemsLength]);

  return (
    <>
      <header className={`header-light scroll-light ${isTrue && "h-355"}`}>
        <div className="container-fluid px-35 ">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex sm-pt10">
                <div className="de-flex-col">
                  <div className="de-flex-col">
                    <div id="logo">
                      <a href="/">
                        <img
                          style={{ width: "150px", height: "130px" }}
                          alt=""
                          className="logo"
                          src="/assets/images/logo-7-light.png"
                        />
                        <img
                          style={{ width: "150px", height: "130px" }}
                          alt=""
                          className="logo-2"
                          src="/assets/images/logo-7.png"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="de-flex-col">
                    <input
                      id="quick_search"
                      className="xs-hide"
                      name="quick_search"
                      placeholder="search item here..."
                      type="text"
                    />
                  </div>
                </div>
                <div className="de-flex-col header-col-mid">
                  <ul id="mainmenu">
                    <li>
                      <Link to="/">Home<span></span></Link>
                    </li>
                    <li>
                      <Link to="/about">About<span></span></Link>
                    </li>
                    <li>
                      <Link to="/collection">Collection<span></span></Link>
                    </li>
                    <li>
                      <Link to="/swaps">How To Swaps<span></span></Link>
                    </li>
                    <li>
                      <Link to="/fair">Fair<span></span></Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ<span></span></Link>
                    </li>
                  </ul>
                  <div className="menu_side_area">
                    {!isLogin ? (
                      <Link to="/login" className="btn-main btn-wallet">
                        <i className="icon_wallet_alt"></i>
                        <span>Login</span>
                      </Link>
                    ) : (
                      <div className="tw-flex tw-flex-row tw-gap-x-10">
                        <Link to="/account" className="btn-main btn-wallet mr-2">
                          <i className="icon_wallet_alt"></i>
                          <span>My Account</span>
                        </Link>
                        <button
                          onClick={() => {
                            localStorage.removeItem("token");
                            navigate("/login");
                          }}
                          className="btn-main btn-wallet mr-2"
                        >
                          <span>LogOut</span>
                        </button>
                        {cartItemsLength > 0 ? (
                          <button
                            type="button"
                            onClick={() => setIsCartModal(true)}
                            className="btn-main btn-wallet px-1"
                          >
                            <FaCartPlus /> <span className="badge badge-light">{cartItemsLength}</span>
                          </button>
                        ) : null}
                      </div>
                    )}
                    <span id="menu-btn" onClick={() => setIsTrue(!isTrue)}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <CartsItemsModal />
    </>
  );
};

export default Navbar;
