/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useEffect, useState } from "react";

import { MoonLoader } from "react-spinners";
// import { rootContext } from "../../context/RootContext";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import { FaCartPlus } from "react-icons/fa";
import useSwapItem from "../../hooks/useSwapItem";
import { useFormik } from "formik";
import * as yup from 'yup';
import { validatar } from "../../utils/validation";
import Swal from "sweetalert2";
import useSwap from "../../hooks/useSwap";
import { addSwapRequest } from "../../services/swapService";
import { useNavigate } from "react-router-dom";
import ProtectionPlan from "../Plans/ProtectionPlan";

const SwapDetail = () => {

  const {swapItemData} = useSwapItem();
  const {swapItems} = useSwap();

  const [loader,setLoader ] = useState(false);
  const [url,setUrl] = useState("");
 const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const formik = useFormik({
    initialValues:{
     
     offered_card_id:"",
     requested_card_id:"",
     quantity:0,
   
     
   
    },
    validationSchema: yup.object({

    offered_card_id:validatar("Offered Id required"),
    requested_card_id:validatar("Request Id required"),
    quantity:validatar("Quantity is  required"),
   
    
        
    
    
    }),
    onSubmit:async(values)=>{
     setLoader(true);
     const result = await addSwapRequest(values);
    
    
     if(result.swap){
    
       setLoader(false)
       Swal.fire({
        icon:'success',
        title:"Account Registration",
        text:result.message,
        showConfirmButton: true,
        timer: 15000
      })

     }
     else{
       setLoader(false)
       Swal.fire({
              icon:'error',
              title:"Account Registration",
              text:result.message,
              showConfirmButton: true,
              timer: 15000
            })
       
     }

     navigate('/account')
    }
 })
 useEffect(()=>{
setUrl(localStorage.getItem('url'))
 })
 useEffect(()=>{
  if(swapItems.length > 0){

    formik.setFieldValue('offered_card_id',swapItems[0].id)
  }

  if(swapItemData.length > 0){

    formik.setFieldValue('requested_card_id',swapItemData[0].id)
  }
 },[swapItemData,swapItems])

  return (
    <>
      {!loader ? (
        <div className="container">
        
          <div className="row pb-5">
            <form id="swapform" onSubmit={formik.handleSubmit}></form>
          
            <div className="col-md-6 col-12 pr-50px">
            {swapItemData && swapItemData.length>0 ?
         <>
         <div className="text-center w-100">
         <h2 className="text-center py-4">
              {/* {cartItems.length > 0 ? cartItems[0].card_collection_title.name : null} */}
              MY Card
            </h2>
         </div>
          {swapItemData.map((item,index)=>(
            <div className="row border-bottom py-2">

            <div className=" col-md-3 position-relative">
                <img src={`${url}${item.card_images[0].image}`} className="w-100" alt="images"/>
                <span className=" c-badge">Mex$ {item.sale_price}</span>
              
            </div>
            <div className=" col-md-9">
                <div className="row">
                    <div className="col-md-8">
                        <h6>{item.name}	</h6>
                    </div>
                    <div className="col-md-4 ">
                        <div className="grid-col-3">
                        Available:{item?.card_details[0]?.quantity}
                      
                    </div>
                        </div>
                </div>
                <div className="row pt-2">
                    <div className=" border-top">
                       <p className="fw-bold m-0 p-0">{item.name}</p>
                       {item.quantity===1 ?
                        
                       
                       <p className="text-danger text-xs  fw-normal">Sorry we only have 1 of these in stock. Please decrease your quantity.</p> : null}
                    </div>
                </div>
            </div>
            </div>   
          ))}
          </>
            : null } 
            </div>
            <div className="col-md-6 col-12 pr-50px">
            {swapItems && swapItems.length>0 ?
         <>
         <div className="text-center w-100">
         <h2 className="text-center py-4">
              {/* {swapItems.length > 0 ? swapItems[0].card_collection_title.name : null} */}
              Swap Card
            </h2>
         </div>
          {swapItems.map((item,index)=>(
            <div key={index} className="row border-bottom py-2">

            <div className=" col-md-3 position-relative">
                <img src={`${url}${item.card_images[0].image}`} className="w-100" alt="images"/>
                <span className=" c-badge">Mex$ {item.sale_price}</span>
              
            </div>
            <div className=" col-md-9">
                <div className="row">
                    <div className="col-md-8">
                        <h6>{item.name}	</h6>
                    </div>
                    <div className="col-md-4 ">
                    <div className="grid-col-3">
                    {console.log("the data is",swapItemData[0]?.card_details[0]?.quantity)}
<button
onClick={()=>formik.setFieldValue("quantity", formik.values.quantity >0 ? formik.values.quantity-1 : 0)}
className="btn btn-primary  font-bold">-</button>
<input form="swapform" type="number" min={0} name="quantity" className="product-count-input w-100 text-center text-dark" value={formik.values.quantity} max={swapItemData[0]?.card_details[0]?.quantity} disabled/>
<button
 onClick={()=>formik.setFieldValue("quantity",swapItemData[0]?.card_details[0]?.quantity !==  formik.values.quantity ?  formik.values.quantity+1 : swapItemData[0]?.card_details[0]?.quantity)}
className="btn btn-primary w-full font-bold">+</button>
</div>
                        </div>
                </div>
                <div className="row pt-2">
                    <div className=" border-top">
                       <p className="fw-bold m-0 p-0">{item.name}</p>
                       {item.quantity===1 ?
                        
                       
                       <p className="text-danger text-xs  fw-normal">Sorry we only have 1 of these in stock. Please decrease your quantity.</p> : null}
                    </div>
                </div>
            </div>
            </div>   
          ))}
          </>
            : null } 
            </div>
            <ProtectionPlan />
          <div className="col-12 m-auto text-center py-5"><button form="swapform" type="submit" className="btn btn-primary">Swap Card</button></div>
            
          </div>
        </div>
      ) : (
        <div className="d-flex  justify-content-center align-items-center vh-100">
          <MoonLoader size={90} loading={loader} color="#333" />
        </div>
      )}
     {/*  */}
  
    </>
  );
};

export default SwapDetail;
