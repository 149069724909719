import React from 'react'
import Swaps from '../../Components/Swaps/Swaps'
import Banners from '../../Components/Banner/Banners'

const SwapsPage = () => {
  return (
    <>
    <Banners name="How To Swaps"/>
     <Swaps />
    </>
  )
}

export default SwapsPage