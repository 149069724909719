import React, {useEffect, useState } from "react";
import { getAllCardsService } from "../../services/cardService";
import { ClipLoader } from "react-spinners";

import useSwap from "../../hooks/useSwap";
import { useNavigate } from "react-router-dom";
export default function SwapableDetail() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [loader,setLoader] = useState(true)

  const allCollectionsData = async () => {
    // setLoader(true)
    const result = await getAllCardsService();

    if (result.card) {
      setData(result.card);
      setLoader(false)
      setUrl(result.imagePath);
      localStorage.setItem('url', result.imagePath);
    } else {
      setLoader(false)
    }
  }
  const {swapItems, addToSwap} = useSwap();
  const [selectedCards,setSelectedCards] = useState([]);
  useEffect(()=>{

    setSelectedCards(swapItems)
    console.log("swaap",swapItems)
  },[swapItems])
//   const handleSelectedCards = (item)=>{
//     setSelectedCards([...selectedCards,item])
     
//   }
const handleSelectedCards = (event,item) => {
    addToSwap(item);


  };
  const testData= ()=>{
   
    navigate('/swaps-card')
   

  }
// change status end
  useEffect(() => {
    allCollectionsData();
  }, []);
  return (
    <div className="container mt-4">
      {!loader ? (
        <div className="New Collections">
            <div className="row">
                <div className="col-md-12 tw-flex tw-flex-row tw-justify-between tw-items-center" key={139}>
          <h2 key={42432}> Collections</h2>
                    <button className="btn btn-primary" onClick={()=>testData()}>Swap Cards</button>
                </div>
            </div>
          <div className="row">
            {data?.map((item, index) => (
              <>
              {item.card_details[0]?.quantity > 0 ?
              <div class=" col-lg-2 col-md-2 col-sm-6 col-xs-12">
              
                 {/* check box start */}
                 
                 {/* check box end */}
                   
              
                <div>
                    <input type="radio" class="checkboxstyle" name="card" id={`checkbox${index}`} value={item.id} onChange={(e)=>handleSelectedCards(e,item)}
                    checked={selectedCards.some(card => card.id === item.id)}
                    />
                    <label className="check-img nft__item" for={`checkbox${index}`}>
                <div class="">

                    <div class="nft__item_wrap">


                        <div class="d-placeholder"></div> 
                        <img src={`${url}${item?.card_images[0]?.image}`} class="lazy" alt="" />

                    </div>
                    <div class="nft__item_info">

                        <h5 className='text-center w-100'>{item?.name}</h5>


                    </div>
                </div>
                </label>
                </div>
            
               
              </div>
              : null}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <ClipLoader loading={loader} color="#333" size={50} />
        </div>
      )}
    </div>
  );
}
