import { useState, useEffect } from 'react';
import { deleteUserCard, getAllSwapService } from '../services/swapService';
import { ChangeDealCardService, ChangeStatusCardService } from '../services/cardService';

const useSwapData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getAllSwapService();
      if (result && result.orders) {
        setData(result.orders);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
   

    fetchData();

    // Clean up function if needed
    return () => {
      // Cleanup code here
    };
  }, []);
  const deleteSwapItem =async (id) => {
    try {
        const response = await deleteUserCard(id)
        console.log("res =>",response)
        if (response && response.message==="Deleted") {
            fetchData();
          return true
        }
        else{
            return false;
        }
        
    } catch (error) {
        setError(error)
        return false
    }
  }

  const handleOrderStatus = async(id,status)=>{
    const result = await ChangeStatusCardService(id,status);
    if(result.order){
      fetchData();
    }
    return result;
  }

  const handleDealStatus = async(id,status)=>{
    const result = await ChangeDealCardService(id,status);
    if(result.order){
      fetchData();
    }
    return result;
  }
  

  return { data,deleteSwapItem, loading, error,setLoading ,handleOrderStatus,handleDealStatus};
};

export default useSwapData;
