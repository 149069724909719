import React from 'react'
import Banners from '../../Components/Banner/Banners'
import SwapDetail from '../../Components/Swaps/SwapDetail'

const SwapCard = () => {
    return (
        <>
            <Banners name="Swap Cards" />
            <SwapDetail />
        </>
    )
}

export default SwapCard