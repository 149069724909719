import React from 'react'
import useSubscription from '../../hooks/useSubscription';
import { addProtectionPlan } from '../../services/subscriptionService';

export default function ProtectionPlan() {
    const {isSubscribed} = useSubscription();
    const handleProtectionPlan=async()=>{
       const result =await addProtectionPlan(5)
       console.log("the result is",result);
       if(result?.link){
        window.location.href = result.link;
       }
    }
  return (
    <>
         {/* protection upgrade plan start */}
         {isSubscribed !==null && isSubscribed.is_protection===0 ?
        <div className='row pt-4 text-dark'>
            <div className='col-md-12 bg-blue py-2 rounded'>
                <div className='p-0'>Upgrade Plan for Protection</div>
                <p className='fw-normal'>   Upgrade to our subscription plan today for enhanced security and reliability! For a <b>$5</b> charge, instead of sending stickers directly to other users, they will be sent to our offices for validation and authentication. If the stickers are valid, the payment is released to the remitter and the stickers are sent to the receiver. Otherwise, both the stickers and the payment are returned. Ensure your transactions are safe and secure by subscribing now!

</p>
                <div className="d-flex justify-content-end">
                    <button  className="btn btn-main btn-wallet mr-2" onClick={()=>handleProtectionPlan()}>Buy Now</button>
                </div>
            </div>
        </div> : null}
        {/* protection upgrade plan end */}
    </>
  )
}
