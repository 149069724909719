/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { validatar } from '../../utils/validation';
import { ForgotPasswordService } from '../../services/authService';
import EmailField from '../Form/EmailField';
import { useNavigate } from 'react-router-dom';
const ForgotForm = () => {
    const [ ,setLoader ] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues:{
              
         email:"",
    
       
        },
        validationSchema: yup.object({
         email: validatar("Email is required")
        
        }),
        onSubmit:async(values)=>{
         setLoader(true);
         const result = await ForgotPasswordService(values);
        
         setLoader(true)
         if(result.status){
          
           Swal.fire({
             icon:'success',
             title:"Forgot Password",
             text:result.message,
             showConfirmButton: true,
             timer: 15000
           })
           setLoader(false)
           navigate('/login')
   
         }
         else{
           setLoader(false)
           Swal.fire({
                  icon:'error',
                  title:"Forgot Password",
                  text:result.errors[0],
                  showConfirmButton: true,
                  timer: 15000
                })
           
         }
         // localStorage.setItem('token',result.accessToken)
         // navigate('/')
        }
     })
    return (
        <>
            <form name="contactForm" id='contact_form' class="form-border py-5" onSubmit={formik.handleSubmit}>
                <h3>Forgot Password</h3>

                <div class="field-set">
                <EmailField label="Email" name="email" placeholder="Enter your Email" formik={formik}/>
                </div>
                <div className='pt-1'>
                    <input type='submit' id='send_message' value='Frogot' class="btn btn-main color-2 w-100" />
                </div>
                <div class="spacer-single"></div>

               


            </form>
        </>
    )
}

export default ForgotForm