import { axiosInstance } from "../utils/axiosHelper"

export const getPlansService =async()=>{
   return await axiosInstance.get('user/plans')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getSinglePlansService =async(id)=>{
   return await axiosInstance.get('user/plans/'+id)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

export const ProtectionOrderSuccessService =async(id,token,pid)=>{

   return await axiosInstance.get(`user/protections/paypal/payment/success/${id}?token=${token}&PayerID=${pid}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
