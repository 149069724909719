import React from 'react'
import { Link } from 'react-router-dom'

export default function SubscribeBanner() {
  return (
    <>
    <div className='container'>
        <div className='row pt-4 text-dark'>
            <div className='col-md-12 bg-blue py-2 rounded'>
                <div className='p-0'>Swapping not enabled</div>
                <p className='fw-light'>Upgrade to our subscription plan today and unlock unlimited card swaps! Enjoy seamless exchanges with fellow users and elevate your experience on our platform. Subscribe now for exclusive perks and seamless swaps!</p>
                <div className="d-flex justify-content-end">
                    <Link to="/plan" className="btn btn-main btn-wallet mr-2">Subsribe Now</Link>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
