import {useState} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { validatar} from '../../utils/validation'
import { registerUserService } from '../../services/authService';
import TextField from '../Form/TextField';
import EmailField from '../Form/EmailField';
import PasswordField from '../Form/PasswordField';
const RegisterForm = () => {
    const [Loader ,setLoader ] = useState(false);

    const navigate = useNavigate();
    const formik = useFormik({
       initialValues:{
        email:"",
        password:"",
        forename:"",
        surname:"",
        username:"",
        cpassword:"",
       },
       validationSchema: yup.object({
        email: validatar("Email is required"),
        forename: validatar("Forename is required"),
        surname: validatar("Surename is required"),
        username: validatar("Username is required"),
        password: yup.string()
        .required('Password requires lowercase, uppercase, number, and special character.')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]*$/, 'Password requires lowercase, uppercase, number, and special character.'),
        cpassword: yup.string()
        .required('Confirm password is required')
        .oneOf([yup.ref('password')], 'Passwords must match'),
       }),
       onSubmit:async(values)=>{
        setLoader(true);
        const result = await registerUserService(values);
       
        setLoader(true)
        if(result.user){
          localStorage.setItem('token',result.token);
          localStorage.setItem('user',JSON.stringify(result.admin));
          Swal.fire({
            icon:'success',
            title:"Account Registration",
            text:result.message,
            showConfirmButton: true,
            timer: 15000
          })
          setLoader(false)
          navigate('/login')
  
        }
        else{
          setLoader(false)
          Swal.fire({
                 icon:'error',
                 title:"Account Registration",
                 text:result.message,
                 showConfirmButton: true,
                 timer: 15000
               })
          
        }
        // localStorage.setItem('token',result.accessToken)
        // navigate('/')
       }
    })
    return (
        <>
            <form name="contactForm" id='contact_form' className="form-border" onSubmit={formik.handleSubmit}>

                <div class="row">

                    <div class="col-md-6">
                        <div class="field-set">
                         <TextField label="Forename" name="forename" placeholder="Enter your Forename" formik={formik}/>
                        </div>
                    </div>

                    <div class="col-md-6">
                      
                        <TextField label="Surename" name="surname" placeholder="Enter your SureName" formik={formik}/>
                      
                    </div>

                    <div class="col-md-6">
                    <TextField label="Username" name="username" placeholder="Enter your Username" formik={formik}/>
                    </div>

                    <div class="col-md-6">
                    <EmailField label="Email" name="email" placeholder="Enter your Email" formik={formik}/>
                    </div>

                    <div class="col-md-6">
                    <PasswordField label="Password" name="password" placeholder="Enter your Password" formik={formik}/>
                    </div>

                    <div class="col-md-6">
                    <PasswordField label="Confirm Password" name="cpassword" placeholder="Enter your Confirm Password" formik={formik}/>
                    </div>


                    <div class="col-md-12">

                        <div className='d-flex justify-content-between align-items-center'>
                            <button type='submit' id='send_message' class="btn btn-main color-2 d-block m-auto " style={{width:"300px"}} disabled={Loader}> Register <ClipLoader loading={Loader}  size={14}/>  </button>
                          
                            <p class='text-dark'>Already You have an account. <Link to='/login'>Login</Link></p>
                        </div>
                        <div class="clearfix"></div>

                    </div>

                </div>
            </form>
        </>
    )
}

export default RegisterForm