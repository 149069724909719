import { useEffect, useState } from 'react';
import { getPlansService, getSinglePlansService } from '../services/planService';
 // Replace with your actual service file

const usePlans = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState([]);
  const [singlePlan, setSinglePlan] = useState([]);
  const fetchPlans = async () => {
    try {
      const data = await getPlansService();
      setPlans(data.plans);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  const fetchSinglePlan = async (id) => {
    try {
      const data = await getSinglePlansService(id);
      setSinglePlan(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
   

    fetchPlans();

    // Cleanup function (optional)
    return () => {
      // Cleanup code if necessary
    };
  }, []);


  return { loading, error, plans,fetchSinglePlan,singlePlan };
};

export default usePlans;
