/* eslint-disable react/jsx-no-target-blank */

import React from "react";
import { FaRegEdit} from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import noImage from "../../../assets/images/no-image.png";
const SubCollectionCard = ({ src, data, deleteFnc, editFnc}) => {
  const getBackgroundColor = (status) => {
    switch (status) {
      case "progress":
        return "#ffd900"; // Green
      case "declined":
        return "#f43405"; // Red
      case "accepted":
        return "#0d6efd"; // Blue
      default:
        return "#ffffff"; // Default color if none of the conditions match
    }
  };

  const color=getBackgroundColor(data.status)
  console.log("the colorr is",color)
  return (
    <>
      <div class="position-relative">
     
        <div className="actions-btn">
          <ReactTooltip
            anchorSelect={`#edit${data.id}`}
            place="left"
            content="Edit"
          />
        
          <ReactTooltip
            anchorSelect={`#delete${data.id}`}
            place="left"
            content="Delete"
          />
          <div className="icon-div tw-cursor-pointer" id={`edit${data.id}`} onClick={() => editFnc(data)}>
            <FaRegEdit size={25} color="#ffffff" />
          </div>
          <div
            className="icon-div tw-cursor-pointer"
            id={`delete${data.id}`}
            onClick={() => deleteFnc(data.id)}
          >
            <MdOutlineDelete size={25} color="#ffffff" />
          </div>
       
        </div>
        <Link to={`/collection/${data.slug}`}>
          <div class="nft__item position-relative">
          
            <div class="nft__item_wrap">
              <div class="d-placeholder"></div>
              <div className="position-absolute  card-sale">
          <span className="rounded p-1 card-badge" style={{
              backgroundColor:color
          }}>{data.status}</span>
        </div>  
              <img src={src === "null" ? noImage : src} class="lazy" alt="" />
            </div>
            <div class="nft__item_info">
                {console.log("the sun data is",data)}
              <h2 className="text-center w-100">{data?.sub_collection_name}</h2>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SubCollectionCard;
