/* eslint-disable react/prop-types */
export default function TextField(props) {
    const {label,name,formik,placeholder,disabled=false, multi=false} = props;
 
  return (
    <div className="pb-3">
     <label>   {label}</label>
             {!multi ?           
            <input
              type="text"
              name={name}
              value={formik.values[name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control mb-0"
              placeholder={placeholder}
             disabled={disabled}
            />
            :
            <textarea
            type="text"
            name={name}
            value={formik.values[name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control mb-0"
            placeholder={placeholder}
           disabled={disabled}
           rows={5}
          />
             }
               {formik.touched[name] && formik.errors[name] && (
          <div className=" text-xs text-danger">
           {formik.errors[name]}
          </div>
       
        )}
    </div>
  )
}
