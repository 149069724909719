/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const useSwap = () => {
  const [swapItems, setSwapItems] = useState([]);

  useEffect(() => {
    // Retrieve cart items from local storage on component mount or update
    const storedCartItems = localStorage.getItem('swapItems');
    const parsedCartItems = storedCartItems ? JSON.parse(storedCartItems) : [];
    setSwapItems(parsedCartItems);
  }, []);

  const addToSwap = (newItem) => {
    setSwapItems([newItem]);
    // localStorage.setItem('swapItems', JSON.stringify([newItem]));
    // const selectedIndex = swapItems.findIndex(card => card.id === newItem.id);

    // if (selectedIndex === -1) {
    //   // If the item is not already in selectedCards, add it
    //   setSwapItems(prevSelectedCards => [...prevSelectedCards, newItem]);
    // } else {
    //   // If the item is already in selectedCards, remove it
    //   setSwapItems(prevSelectedCards => {
    //     const updatedSelectedCards = [...prevSelectedCards];
    //     updatedSelectedCards.splice(selectedIndex, 1);
    //     return updatedSelectedCards;
    //   });
    // }
  };
  const removeFromSwap = (itemId) => {
    setSwapItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const updateSwapItemQuantity = (itemId, newQuantity) => {
    setSwapItems((prevItems) => {
      const updatedItems = [...prevItems];
      const itemIndex = updatedItems.findIndex((item) => item.id === itemId);
      if (itemIndex !== -1) {
        updatedItems[itemIndex].quantity = newQuantity;
      }
      return updatedItems;
    });
  };

  // Helper function to persist cart items to local storage
  const persistCart = () => {
    if(swapItems.length>0){

        localStorage.setItem('swapItems', JSON.stringify(swapItems));
    }
  };

  // Call persistCart after any cart item changes for persistence
  useEffect(() => {
    persistCart();
  }, [swapItems]);

  return { swapItems, addToSwap, removeFromSwap, updateSwapItemQuantity };
};

export default useSwap;