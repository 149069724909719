/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { getSubecriptions } from '../services/subscriptionService';

const useSubscription = () => {
  const [isSubscribed, setIsSubscribed] = useState(null);


  const handleCheckSubscription =async ()=>{
    const response = await getSubecriptions();
    
    setIsSubscribed(response.subscription);
    // if(response.subscription){
    //     console.log("the message is",response.subscription);
    // }
    // else{
    //         setError(response.message)
    // }
  }

  useEffect(() => {
    handleCheckSubscription();
  }, []);

 
  return { isSubscribed };
};

export default useSubscription;