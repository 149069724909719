import { axiosInstance } from "../utils/axiosHelper"

export const addOrderService =async(data)=>{
   return await axiosInstance.post('user/orders',data)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getOrderService =async()=>{
   return await axiosInstance.get('user/orders')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

export const getOrderRequestService =async()=>{
   return await axiosInstance.get('user/order/order-request')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const sucessOrder =async(id,token)=>{

    return await axiosInstance.get(`user/orders/paypal/payment/success/${id}?token=${token}`)
    .then((res)=>{
     return res.data
    })
    .catch((err)=>{
     return err.response.data
    })
 }
 