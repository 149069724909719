// import { countries } from "../countries";

/* eslint-disable react/prop-types */
export default function DealField(props) {
  const { label, name, formik, placeholder, disabled = false ,options} = props;

  return (
    <>
      <label
      
      >
        {label}
      </label>
      <select
        className="form-control mb-0"
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={placeholder}
        disabled={disabled}
      >
        <option value="" disabled>
         {placeholder}
        </option>
        {options.map((option,index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
        {/* Add more options as needed */}
      </select>
      {formik.touched[name] && formik.errors[name] && (
        <div className=" text-danger">
          {formik.errors[name]}
        </div>
      )}
    </>
  );
}
