import { axiosInstance } from "../utils/axiosHelper"



export const getAllCollectionsbyUserService =async()=>{
   return await axiosInstance.get('user/collectionrequests')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getAllCollectionsTitle =async()=>{
   return await axiosInstance.get('user/cardCollectionTitles')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}



export const deleteUserCardRequest =async(id)=>{
   return await axiosInstance.delete(`user/collectionrequests/${id}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const deleteSubCollectionUserCardRequest =async(id)=>{
   return await axiosInstance.delete(`user/subcollectionrequest/${id}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const updateUserCard=async(data)=>{

   const formdata = new FormData();
   formdata.append('name',data.name)
   formdata.append('deal',data.deal)
   if(typeof data.card_collection_title_id==="string"){

      formdata.append('card_collection_title_id',data.card_collection_title_id.split('&')[0])
   }
   else{
      formdata.append('card_collection_title_id',data.card_collection_title_id)
   }
  
   if(typeof data.card_collection_sub_title_id==="string"){

      formdata.append('card_collection_sub_title_id',data.card_collection_sub_title_id.split('&')[0])
   }
   else{
      formdata.append('card_collection_sub_title_id',data.card_collection_sub_title_id)
   }

   if(typeof data.card_type_id==="string"){

      formdata.append('card_type_id',data.card_type_id.split('&')[0])
   }
   else{
      formdata.append('card_type_id',data.card_type_id)
   }

   if(typeof data.is_for_sale==="string"){

      formdata.append('is_for_sale',data.is_for_sale.split('&')[0])
   }
   else{
      formdata.append('is_for_sale',data.is_for_sale)
   }

   
   formdata.append('sale_price',data.sale_price)
 
   if(typeof data.image !=='string'){

      formdata.append('image[0]',data.image)
   }
   formdata.append('quantity',data.quantity)
   formdata.append('_method',"PUT")
   return await axiosInstance.post(`user/cards/${data.id}`,formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}



export const cardBySubCollectionsSlugById =async(slug,id)=>{
   return await axiosInstance.get(`cards?cardCollectionSubTitleSlug=${slug}&user_id=${id}&is_for_sale=1`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const cardBySubCollectionsSlug =async(slug)=>{
   return await axiosInstance.get(`cards?cardCollectionSubTitleSlug=${slug}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}


export const swapCardsService =async(slug,id)=>{
   return await axiosInstance.get(`user/cardswaps?cardCollectionSubTitleSlug=${slug}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const cardSwapMessage =async(data)=>{
   return await axiosInstance.post(`user/cardswaps`,data)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}





export const getAllSubCollectionsbyUserService =async()=>{
   return await axiosInstance.get('user/subcollectionrequest')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}