/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import {  sucessSubecriptions } from '../../services/subscriptionService'
import { Link, useParams, useSearchParams,  } from 'react-router-dom'

export default function SubscriptionSucess() {
    const {id} = useParams();
    const [loading,setLoading] = useState(true);
    const [searchParams,] = useSearchParams();
    const PayerID= searchParams.get('PayerID')
    const token= searchParams.get('token')
   
    const hanldeSubscriptions = async(id,token,payerId)=>{
    const response = await sucessSubecriptions(id,token,payerId);
    if(response.subscriptionPlan){
        setLoading(false);
        // window.location.href = "/";
        return true;
    }
    console.log("the repsone is",response)

    }
    useEffect(()=>{
        hanldeSubscriptions(id,token,PayerID)
    },[token])
  return (
    <>
    {!loading ?
      <div className="plan container mt-5">
         <div className="row">
            <div className="col-md-6 m-auto pb-4">
                <h3 className='text-center'>Successful Payment Confirmation</h3>
                <p>Thank you for your payment! Your sticker swap request has been successfully processed. We're thrilled to have you join our community of sticker enthusiasts. Your stickers will be on their way soon!</p>
                <p>If you have any questions or need further assistance, feel free to reach out to our support team at <a href="mailto:support@example.com">support@example.com</a>.</p>
                <p>Happy swapping!</p>
                <Link to="/account" className="btn btn-main btn-wallet py-3">Dashboard</Link>
            </div>
         </div>
      </div>
      :
      <div className='container vh-100 d-flex justify-content-center align-items-center'>
        <ClipLoader size={40} color="#333" />
      </div>
}
    </>
  )
}
