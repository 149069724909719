/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  profileDataServce,
  UpdateDeliveryMethodService,
  UpdateUserProfileService,
} from "../../services/authService";
import EmailField from "../Form/EmailField";
import TextField from "../Form/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { validatar } from "../../utils/validation";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import ImageField from "../Form/ImageField";
import UpdatePassword from "./UpdatePassword";
import DeliveryCheckBox from "../Form/DeliveryCheckBox";
export default function Profile() {
  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(true);
  const [resizeCropper, setResizerCropper] = useState(false);
  // const [loader,setLoader] = useState(false)

  const profileData = async () => {
    // setLoader(true)
    const result = await profileDataServce();

    if (result?.admin) {
      formik.setValues(result.admin);
      const deliveryData=[]
      if(result.admin.delivery_method){
        result.admin.delivery_method.forEach((item)=>{
          if(item.value ===1){

            deliveryData.push(item.key)
          }
        })
      }
      formik.setFieldValue('delivery_method', deliveryData)
      // console.log("the data is",deliveryData)
      setLoader(false);
      setUrl(result.imagepath);
    } else {
      setLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      email: "",
      image: "",
      forename: "",
      surname: "",
      username: "",
      state:"",
      zipcode: "",
      delivery_method:[]
    },
    validationSchema: yup.object({
      email: validatar("Email is required"),
      forename: validatar("Forename is required"),
      surname: validatar("Surename is required"),
      username: validatar("Username is required"),
      state: validatar("State is required"),
      zipcode: validatar("State is required"),
    }),
    onSubmit: async (values) => {
      console.log("the delivery method is",values.delivery_method)
      setLoader(true);
      const delivery_result= await UpdateDeliveryMethodService(values.delivery_method);
      console.log("the response is",delivery_result)
      const result = await UpdateUserProfileService(values);

      setLoader(true);
      if (result.user) {
        Swal.fire({
          icon: "success",
          title: "Account Registration",
          text: result.message,
          showConfirmButton: true,
          timer: 15000,
        });
        profileData();
        setLoader(false);
      } else {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Account Registration",
          text: result.message,
          showConfirmButton: true,
          timer: 15000,
        });
      }
      // localStorage.setItem('token',result.accessToken)
      // navigate('/')
    },
  });
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    let  formdata =[]
    formik.values.delivery_method?.map((value)=>formdata.push(value))
    let newSelectedOptions = formdata;

    if (checked && !newSelectedOptions.includes(value)) {
      newSelectedOptions.push(value);
    } else {
      newSelectedOptions = newSelectedOptions.filter(option => option !== value);
    }

    formik.setFieldValue('delivery_method', newSelectedOptions);
  };
  useEffect(() => {
    profileData();
  }, []);
  return (
    <div className="pb-3">
      {!loader ? (
        <div className="New Collections">
          <div className="contianer">
            <div className="row">
              <div className="col-md-9 col-12">
                <div className="row">
                  <div className="col-12 text-center">
                    {" "}
                    <h2> Profile</h2>
                  </div>
                  <div
                    className={`${
                      resizeCropper ? "col-12" : "col-md-3  "
                    } col-12`}
                  >
                    <div className="shadow">
                      <ImageField
                        label="Profile Image"
                        name="image"
                        placeholder="Enter Title"
                        formik={formik}
                        url={url}
                        id="contact_form"
                        setResizerCropper={setResizerCropper}
                      />
                    </div>
                    {/* <img src={url+data.image} className="img-fluid"/> */}
                  </div>
                  <div
                    className={`${
                      resizeCropper ? "d-none" : "d-block col-md-9"
                    } col-12`}
                  >
                    <form
                      name="contactForm"
                      id="contact_form"
                      className="form-border"
                      onSubmit={formik.handleSubmit}
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="field-set">
                            <TextField
                              label="Forename"
                              name="forename"
                              placeholder="Enter your Forename"
                              formik={formik}
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <TextField
                            label="Surename"
                            name="surname"
                            placeholder="Enter your SureName"
                            formik={formik}
                          />
                        </div>

                        <div class="col-md-6">
                          <TextField
                            label="Username"
                            name="username"
                            placeholder="Enter your Username"
                            formik={formik}
                          />
                        </div>

                        <div class="col-md-6">
                          <EmailField
                            label="Email"
                            name="email"
                            placeholder="Enter your Email"
                            formik={formik}
                          />
                          
                        </div>
                        <div class="col-md-6">
                          <TextField
                            label="State"
                            name="state"
                            placeholder="Enter your State"
                            formik={formik}
                          />
                        </div>
                        <div class="col-md-6">
                          <TextField
                            label="Zip Code"
                            name="zipcode"
                            placeholder="Enter your Zip Code"
                            formik={formik}
                          />
                        </div>
                        <div className="col-12 m-auto py-3">
                          <p className="text-black">
                            Delivery Method accepted (Please select all the
                            methods you accept for your trades. This will appear
                            as part of the result of the other users in their
                            searches)
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <h2>Update Password </h2>
                <UpdatePassword />
              </div>
            </div>

            {/* delivery method start */}
            <div className="row">
              {/* delivery method start */}
              <div className="col-12">
                <div className="row py-4">
                  {/* check box start */}
                  <div className="col-3">
                    <DeliveryCheckBox
                      checkBoxHandler={handleCheckboxChange}
                      formik={formik}
                      label="Presential Users agree a one to one meeting in a public place."
                      name="delivery_method"
                    />
                  </div>
                  {/* check box end */}
                  {/* check box start */}
                  <div className="col-3">
                    <DeliveryCheckBox
                      checkBoxHandler={handleCheckboxChange}
                      formik={formik}
                      label="Courier Users send the stickers by postal service, or the courier of their preference."
                      name="delivery_method"
                    />
                  </div>
                  {/* check box end */}
                  {/* check box start */}
                  <div className="col-3">
                    <DeliveryCheckBox
                     checkBoxHandler={handleCheckboxChange}
                     formik={formik}
                      label="Estampers Users send the stickers through Estampers validation system.*Your actual plan need to be upgraded for this option."
                      name="delivery_method"
                    />
                  </div>
                  {/* check box end */}
                  {/* check box start */}
                  <div className="col-3">
                    <DeliveryCheckBox
                      checkBoxHandler={handleCheckboxChange}
                      formik={formik}
                      label="Events Users attend events promoted by Estampers for its community ."
                      name="delivery_method"
                    />
                  </div>
                  {/* check box end */}
                </div>
              </div>
              {/* delivery method end */}
              {/* submit button start */}
              <div class="col-md-12">
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    type="submit"
                    form="contact_form"
                    class="btn btn-main color-2 d-block m-auto "
                    style={{ width: "300px" }}
                    disabled={loader}
                  >
                    {" "}
                    Update Profile <ClipLoader
                      loading={loader}
                      size={14}
                    />{" "}
                  </button>
                </div>
                <div class="clearfix"></div>
              </div>
              {/* submit button end */}
            </div>
            {/* delivery method end */}
            {/* Security Notifiaction row start */}
            <div className="row pt-4">
              <div className="col-12 security-box-notification">
                <p>
                Security First - Be specially precautious for one to one meetings. Always establish a well known place, in regular hours . We strongly recommend to never attend meeting in solitaire places. Let others know when you are attending a meeting. Estampers is not involved nor participate in anyway in these meetings.

                </p>
              </div>
            </div>
            {/* Security Notifiaction row end */}
          </div>
        </div>
      ) : (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <ClipLoader loading={loader} color="#333" size={50} />
        </div>
      )}
    </div>
  );
}
