/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Banners from "../../Components/Banner/Banners";
import AccountPanel from "../../Components/Collection/AccountPanel";
import { rootContext } from "../../context/RootContext";
import { useNavigate } from "react-router-dom";
import SubscribeBanner from "../../Components/Banner/SubscribeBanner";
import useSubscription from "../../hooks/useSubscription";

const MyAccount = () => {
  const { bannerTitle } = useContext(rootContext);
  const { isSubscribed } = useSubscription();
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <Banners name={bannerTitle === "" ? "My Account" : bannerTitle} />

      {isSubscribed === null ? <SubscribeBanner /> : null}
      <AccountPanel />
    </>
  );
};

export default MyAccount;
