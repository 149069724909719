/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect } from 'react'
import Banners from '../../Components/Banner/Banners'
import { useNavigate } from 'react-router-dom'
import OrderSuccess from '../../Components/cart/OrderSuccess'

const SuccessOrder = () => {
   

     const navigate = useNavigate();
    useEffect(()=>{
     if(!localStorage.getItem('token')) {
        navigate('/login')
     }
    },[])
    return (
        <>

            <Banners name="Order Complated"/>
        
            <OrderSuccess/>


        </>
    )
}

export default SuccessOrder