/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import {  useParams } from "react-router-dom";
import {
  cardBySubCollectionsSlug,
  cardBySubCollectionsSlugById,
  swapCardsService,
} from "../../services/cardService";
import { MoonLoader } from "react-spinners";

// import { Button } from "react-bootstrap";

// import useSwapItem from "../../hooks/useSwapItem";
import ForSale from "./ForSale";
import ForSwap from "./ForSwap";
const DetailCollection = () => {
  const [isSwap, setIsSwap] = useState(false);

  // const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [swapData, setSwapData] = useState([]);
  const [url, setUrl] = useState("");
  const [, setUserId] = useState("");
  const [singleData, setSingleData] = useState({});
  const [loader, setLoader] = useState(false);
  // const { isLogin } = useContext(rootContext);
  const [isLogin, setIsLogin] = useState(false);
  // const { addToSwapCart} = useSwapItem();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setIsLogin(true);
      let user = JSON.parse(localStorage.getItem("user"));
      setUserId(user.id);
    }
  }, []);
  const allSubCollectionsData = async (name) => {
    setLoader(true);
    let user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    let result = "";
    if (!token) {
      
      result = await cardBySubCollectionsSlug(name);
    } else {
      result = await cardBySubCollectionsSlugById(name, user.id);
    }
    if (result.card) {
      setData(result.card);
      setLoader(false);
      setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    allSubCollectionsData(id);
    if(token){

      handleSwapData(id);
    }
  }, [id]);

  // const handleCartImage=(item)=>{
  //  const result =addToSwapCart(item);
  //   if(result){

  //      navigate('/select-swaps-card');
  //   }
  // }

  const handleSwapData = async (name) => {
    setLoader(true);
    let user = JSON.parse(localStorage.getItem("user"));

    let result = await swapCardsService(name, user.id);
    if (result) {
      setSwapData(result);
     
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  return (
    <>
      {!loader ? (
        <>
          <div className="container">
            <div className="row">
              <div class="d-flex justify-content-between align-items-center">
                <h2>
                  {data.length > 0
                    ? data[0].card_collection_title.name +
                      "/" +
                      id.replace("-", " ")
                    : null}
                </h2>
                {isLogin ?
                <div className="pt-2">
                  {isSwap ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => setIsSwap(!isSwap)}
                    >
                      Sale
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => setIsSwap(!isSwap)}
                    >
                      Swap
                    </button>
                  )}
                </div> : null}
              </div>
            </div>
          </div>
          {!isSwap ? (
            <ForSale
              data={data}
              isLogin={isLogin}
              setSingleData={setSingleData}
              singleData={singleData}
              url={url}
            />
          ) : (
            <ForSwap
            swapData={swapData}
            collection={id} />
          )}
        </>
      ) : (
        <div className="d-flex  justify-content-center align-items-center vh-100">
          <MoonLoader size={90} loading={loader} color="#333" />
        </div>
      )}
      {/*  */}
    </>
  );
};

export default DetailCollection;
