import React, { useState } from 'react'


export default function PlanCard({name,description,duration,period,price,hanldePlanSubscriptions,id,buttonLoader}) {
  const [upgradePlan,setUpgradePlan] = useState(0);
  
  const handleCheckboxChange = (event) => {
    setUpgradePlan(event.target.checked ? 5 : 0);
  };

  return (
    <div className="text-black">
    <div className="card py-4  h-100">
      <div className="card-body d-flex flex-column">
      
        <div className="card-title mb-4 text-center fs-2">{name}</div>
        <div className="">
          
             
              <span className="small ">{description}</span>
          
        </div>
        <div className="text-center mt-auto mb-4">
          <span className="fw-bold fs-2 ">{duration}</span>/ {period}
       
        </div>
          <div className="">
          
             
              <span className="small">
              <div class="form-check d-flex gap-x-5">
              {price !== 0 &&(
              <>
              <input type="checkbox" name="upgrade" value="5" className='form-check-input upgrade-checkbox' onChange={(event)=>handleCheckboxChange(event)}  id="flexCheckDefault"/>
              <label class="form-check-label" for="flexCheckDefault">

              Upgrade for enhanced security! For a <b>$5</b> charge, stickers are validated before sending. Ensure safe, reliable transactions!
              </label>
              </>
              )}
</div>
                </span>
          
        </div>
      
        <div className="text-center"><button type="button" className="btn btn-main btn-wallet py-3" onClick={()=>hanldePlanSubscriptions(id,upgradePlan,price)}>
          {buttonLoader === id ? "Loading..." : `Choose Plan $${price}`}
         </button></div>
      </div>
    </div>
  </div>
 
  )
}
