/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { rootContext } from "../../context/RootContext";
import useCart from "../../hooks/useCart";
import { Link } from "react-router-dom";
export default function CartsItemsModal() {
    const { isCartModal,setIsCartModal} = useContext(rootContext);
    const {cartItems,updateToCart,removeFromCart,fetchData} = useCart();

    // const navigate = useNavigate();
    const handleCartItems = async(id,price,qty)=>{
      const result = await updateToCart(id,price,qty)
     
    }
    useEffect(()=>{
     if(isCartModal){
      fetchData()
     }
    },[isCartModal])


 
  return (
    <div className="position-relative">
      <Modal
        show={isCartModal}
        onHide={() => setIsCartModal(false)}
        animation={false}
        dialogClassName="bg-white"
        size="lg"
      >
        <Modal.Header className="border-bottom-0 pb-0" closeButton>
          <Modal.Title as="h4" className="w-100 text-center p-0 ">
          Your shopping basket

          </Modal.Title>
        </Modal.Header>
       
        <Modal.Body className="text-dark">
         <div className="container">
         {/* card start  */}
         {cartItems && cartItems.length>0 ?
         <>
          {cartItems.map((item,index)=>(
            <div className="row border-bottom py-2">

            <div className=" col-md-2 position-relative">
                <img src="https://gotneedswap.co.uk/assets/products/mag24/gns-maeg24-ic-coming-soon.png" className="w-100" alt="images"/>
                <span className=" c-badge">€{item.price}</span>
              
            </div>
            <div className=" col-md-10">
                <div className="row">
                    <div className="col-md-9">
                        <h6>{item.card.name}
                        	</h6>
                          <div>
                            <p><button className="btn btn-sm btn-primary " onClick={()=>removeFromCart(item.id)}><i class="fa fa-trash"></i></button></p>
                        <p className="text-right ">€{item.price}x{item.qty}=€{item.price*item.qty}</p>
                          </div>
                    </div>
                    <div className="col-md-3 ">
                        <div className="grid-col-3">

                        <button
                        onClick={()=>item.qty >1 ? handleCartItems(item.card.id,item.price,item.qty-1) : 1 }
                        className="btn btn-primary  font-bold">-</button>
                        <input type="number" min={0} max={3} className="product-count-input w-100 text-center text-dark" value={item.qty} disabled/>
                        <button
                         onClick={()=>handleCartItems(item.card.id,item.price,item.qty+1)}
                        className="btn btn-primary w-full font-bold">+</button>
                    </div>
                        </div>
                </div>
                <div className="row pt-2">
                    {/* <div className=" border-top">
                      
                       {item?.qty <2 ?
                       <p className="text-danger text-xs  fw-normal">Sorry we only have 1 of these in stock. Please decrease your quantity.</p> : null}
                    </div> */}
                </div>
            </div>
            </div>   
          ))}
          </>
            : null } 
           {/* card end */}
         </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="tw-flex tw-justify-between tw-justify-between tw-flex-row w-100">
                <Link to="/checkout" onClick={()=>setIsCartModal(false)} className="btn btn-outline-primary">ORDER NOW</Link>
                {/* <Link to="/checkout" onClick={()=>setIsCartModal(false)} className="btn btn-primary">GO TO CHECKOUT</Link> */}
            </div>
        </Modal.Footer>
      </Modal>
    
    </div>
  )
}
