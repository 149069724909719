import { axiosInstance } from "../utils/axiosHelper"

export const getAllCardsTypeService =async()=>{
   return await axiosInstance.get('user/cardTypes')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

