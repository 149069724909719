import { axiosInstance } from "../utils/axiosHelper"

export const getAllCollectionsService =async()=>{
   return await axiosInstance.get('user/cardCollectionTitles')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getAllCollectionsPublicService =async()=>{
   return await axiosInstance.get('cardCollectionTitles')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getAllSubCollectionsPublicService =async()=>{
   return await axiosInstance.get('user/cardCollectionSubTitles')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
