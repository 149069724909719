/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import Table from "react-bootstrap/Table";
import { Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FaCartPlus } from "react-icons/fa";
import useSubscription from '../../hooks/useSubscription';
import useCart from '../../hooks/useCart';
export default function ForSale({data,isLogin,setSingleData,singleData,url}) {
const [show, setShow] = useState(false);
const {isSubscribed} = useSubscription();
const { addToCart} = useCart();
  return (
    <>
      <div className="container">
          <div className="row pb-5">
          
  
            <div className={!isLogin ? "col-12 col-md-8" : "col-12"}>
              <Table striped="columns" bordered hover size="sm">
                <thead className="text-center">
                  <tr>
                    <th>Number</th>
                    <th>Name</th>
                    <th>Card Type</th>
                    <th>Quantity</th>
                    {isLogin ? <th>For Sale</th> : null}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.card_types.name}</td>
                      <td>{item?.card_details[0]?.quantity}</td>
                      {isLogin ? (
                        <>                     
                    
                        <td className="tw-flex tw-items-center tw-flex-row tw-justify-center tw-gap-x-10 tw-flex-row">
                     
                          <button
                            className="btn btn-primary"
                            disabled={item?.card_details[0]?.quantity>0 ? false:true}
                            onClick={() => {
                              setShow(true);
                              setSingleData(item);
                            }}
                          >
                            Mex$ {item.sale_price} Buy Now
                          </button>
                        </td>
                        </>
) : null}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {!isLogin ? (
              <div className="col-lg-4">
                <h2>Start Swapping</h2>
                <p>
                  <Link to="/signup">Sign up</Link> or{" "}
                  <Link to="/login">Login</Link> to your Got Need Swap account
                  to start trading with other members today.
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        dialogClassName="bg-white"
      >
        <Modal.Header className="border-bottom-0 pb-0" closeButton>
          <Modal.Title as="h4" className="w-100 text-center p-0 ">
            {singleData?.name}
          </Modal.Title>
        </Modal.Header>
        <div className="w-100 text-dark p-0 d-flex justify-content-center align-items-center">
          <p className="">{singleData?.card_types?.name}</p>
        </div>
        <Modal.Body className="text-dark">
          <div className="text-center p-0">
            {/* <button className="btn btn-primary" onClick={()=>{
             handleCartImage(singleData)
            }}>
               <span className="cpl-20">Swap Card </span> -{" "}
              Mex$ {singleData?.sale_price}
            </button> */}
            <span className="px-4"></span>
          
            {isSubscribed  !==null && isSubscribed?.plan?.name==="PRO" ?
           null : 
           <button className="btn btn-primary" onClick={()=>{
            addToCart(singleData)
           }}>
             <FaCartPlus /> <span className="cpl-20">Add to Cart </span> -{" "}
             Mex$ {singleData?.sale_price}
           </button>
           }
            <div className="mt-3">
    {singleData && singleData.card_images && singleData.card_images.length > 0 ?
        <img src={url + singleData.card_images[0].image} className="w-100" />
        : null}
</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
