/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useState } from "react";
import { ClipLoader } from "react-spinners";

export default function Fair() {
  const [loader, ] = useState(false);
  return (
    <div className="pb-3">
      {!loader ? (
        <div className="container">
          <h1 className="pt-3 text-black">Fair Play </h1>
          <div className="text-black">
            {/* heading start */}
            <div className="py-2">
             
              <p class="fw-normal ">
              Estampers is a site to help collectors to complete their collections in a easy collaborative interaction with other users. Estampers main purpose is to have a great time while completing a collection, using the technology to  get that very last missing sticker, making it easier than back in the day when you only could swap with your school fellas, but also giving the users the opportunity to meet other persons in one to one meetings or in the events promoted by Estampers.

              </p>
             
            </div>
            {/* heading end */}
            {/* heading start */}
            <div className="py-2">
              <h5>Quality </h5>
            
              <p class="fw-normal ">
              We all are collectors, and we treat our albums and stickers with all due care, and we all expect that each other user take the same care for the stickers as we do. Is for this reason that  we ask our users to swap only stickers in good conditions. Please avoid sending damage stickers and we strongly recommend to report those users that doesn't comply with this rule.

              </p>
         
            </div>
            {/* heading end */}
            {/* heading start */}
            <div className="py-2">
              <h5>Respect </h5>
            
              <p class="fw-normal ">
              We ask all our users to respect each other in all the interactions, either by mail or face to face, we encourage all users to treat each other respectfully.
              </p>
            
         
            </div>
            {/* heading end */}
            {/* heading start */}
            <div className="py-2">
              <h5>Security
              </h5>
            
              <p class="fw-normal ">
              Your security is first, take all the precautions either in face to face meeting, or in the events promoted by Estampers. Don't share personal information that could be misused. Report any misconduct from other users in the contact form. 


               </p>
           
         
            </div>
            {/* heading end */}
            {/* heading start */}
            <div className="py-2">
              <h5>Fair price

              </h5>
            
              <p class="fw-normal ">
              Even though Estampers main purpose is to swap stickers with other users, we provide our users with a selling option considering that sometimes we swap and complete our albums and still keep a lot of stickers which can't be swapped, but we still can sell them to save money for our next collection. Even though Estampers does not gets involved in the selling price, as this is only decision of the users, we recommend our users to set reasonable fair prices, reminding that the selling process is not looking for a profit but for cost recovery for our next collection. You can report any abuse using the contact form. For collector pieces, which are really high valued, you can contact Estampers by email contacto@estampers.com, we could help you get in contact with other collectors.


               </p>
           
         
            </div>
            {/* heading end */}
          </div>
        </div>
      ) : (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <ClipLoader loading={loader} color="#333" size={50} />
        </div>
      )}
    </div>
  );
}
