/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const ColllectionsCard = ({ src, name = "UnKnown" }) => {
  return (
    <>
      <div class="">
        <div class="nft__item">
          <div class="nft__item_wrap">
            <div class="d-placeholder"></div>

            <img
              src={src}
              class="lazy"
              className="image-cover"
              alt={`alt-${name}`}
            />
          </div>
          <div class="nft__item_info">
            <h2 className="text-center w-100">{name}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColllectionsCard;
