import React from 'react'
import Banners from '../../../Components/Banner/Banners'
import LoginForm from '../../../Components/Auth/LoginForm'

const Login = () => {
    return (
        <>

            <Banners name='Login' />




            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <LoginForm />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Login