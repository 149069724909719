import React, { useState } from 'react'
import PasswordField from '../Form/PasswordField'
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { UpdateUserPasswordService } from '../../services/authService';
import { ClipLoader } from 'react-spinners';
import { validatar } from '../../utils/validation';
export default function UpdatePassword() {
    const [loader,setLoader]= useState(false)
    const formik = useFormik({
        initialValues:{
            old_password:"",
            new_password:"",
            confirm_password:""
       
        },
        validationSchema: yup.object({
            old_password:validatar("password is required"),
            new_password:yup.string()
            .required('Password requires lowercase, uppercase, number, and special character.')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]*$/, 'Password requires lowercase, uppercase, number, and special character.'),
            confirm_password: yup.string()
            .required('Confirm password is required')
            .oneOf([yup.ref('new_password')], 'Passwords must match')
        
        }),
        onSubmit:async(values)=>{
  
            setLoader(true)
            const result = await UpdateUserPasswordService(values);
            
            
            if(result.user){
             setLoader(false)
           Swal.fire({
             icon:'success',
             title:"User Password",
             text:result.message,
             showConfirmButton: true,
             timer: 15000
           })
         
          
   
         }
         else{
          setLoader(false)
           Swal.fire({
                  icon:'error',
                  title:"User Password",
                  text:result.message,
                  showConfirmButton: true,
                  timer: 15000
                })
           
         }
         // localStorage.setItem('token',result.accessToken)
         // navigate('/')
        }
     })
  return (
    <>
       <form name="contactForm" id='password-form' className="form-border" onSubmit={formik.handleSubmit}>

<div class="row">

    <div class="col-md-12">
        <div class="field-set">
         <PasswordField label="Old Password" name="old_password" placeholder="Enter your old password" formik={formik}/>
        </div>
    </div>
    <div class="col-md-12">
        <div class="field-set">
         <PasswordField label="New Password" name="new_password" placeholder="Enter your new password" formik={formik}/>
        </div>
    </div>
    <div class="col-md-12">
        <div class="field-set">
         <PasswordField label="Confirm Password" name="confirm_password" placeholder="Enter your Confirm password" formik={formik}/>
        </div>
    </div>


    


    <div class="col-md-12">

        <div className='d-flex justify-content-between align-items-center'>
            <button type='submit' form='password-form' class="btn btn-main color-2 d-block m-auto " style={{width:"300px"}} disabled={loader}> Update Profile <ClipLoader loading={loader}  size={14}/>  </button>
          
        </div>
        <div class="clearfix"></div>

    </div>

</div>
</form>
    </>
  )
}
