import React from "react";

export default function DeliveryCheckBox(props) {
  const { label, name, checkBoxHandler,formik } = props;
  return (
    <>
      <div className="form-check delivery-box">
        <div>
          <input
            className="form-check-input delivery-checkbox"
            type="checkbox"
            name={name}
            value={label}
            onChange={checkBoxHandler}
            
            onBlur={formik.handleBlur}
            checked={formik.values.delivery_method?.includes(label)}
          />
        </div>
        <label
          className="form-check-label delivery-box-label"
          for="flexCheckChecked"
        >
          {label}
        </label>
      </div>
    </>
  );
}
