import React from 'react'
import Banners from '../../../Components/Banner/Banners'
import ForgotForm from '../../../Components/Auth/ForgotFrom'

const Forgot = () => {
    return (
        <>
            <Banners name='Forgot Password' />

            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <ForgotForm />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Forgot