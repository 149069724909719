import React from 'react'
import Banners from '../../Components/Banner/Banners'
import Fair from '../../Components/Account/Fair';
const FairPage = () => {
  return (
    <>
    <Banners name="Fair"/>
   <Fair/>
    </>
  )
}

export default FairPage