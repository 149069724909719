import React from 'react'
import Banners from '../../Components/Banner/Banners'
import SwapableDetail from '../../Components/Swaps/SwapableDetail'

export default function SwapableCard() {
  return (
    <>
      <Banners name="Select Cards" />
            <SwapableDetail />
    </>
  )
}
