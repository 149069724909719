/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect } from 'react'
import Banners from '../../Components/Banner/Banners'
import { useNavigate } from 'react-router-dom'
import Plans from '../../Components/Plans/Plans'

const Plan = () => {
   

     const navigate = useNavigate();
    useEffect(()=>{
     if(!localStorage.getItem('token')) {
        navigate('/login')
     }
    },[])
    return (
        <>

            <Banners name="Subscription Plan"/>
        
            <Plans/>


        </>
    )
}

export default Plan