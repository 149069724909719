import { ClipLoader } from 'react-spinners'
import useOrder  from '../../hooks/useOrder';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { cardSwapMessage } from '../../services/cardService';
import { validatar } from '../../utils/validation';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import TextField from '../Form/TextField';
export default function Orders() {
 const {orderItems,loading,handleOrderStatus,setLoading,handleDealStatus} = useOrder()
 

 const [userData,setuserData]=useState({});
 const [emailModal,setEmailModal]=useState(false);
  const formik = useFormik({
    initialValues: {
      member:"",
      collection:"none",
      card_got:"",
      card_needed:"",
      message:"",
      email:"",
    },
    validationSchema: yup.object({
     card_got:validatar("Card Got is required"),
     card_needed:validatar("Card Need is required"),
     message:validatar("Message is required"),
    }),
    onSubmit: async (values) => {
      
       const result = await cardSwapMessage(values)
       console.log("the reuslt is",result)
       if(result){
        setEmailModal(false)
        Swal.fire('success','Message has been successfully','success')
      }
      else{
         Swal.fire('error','Something went wrong','error')
        
       }
      }
    
    
  });


const handleUploadStatus= async(id,status)=>{
  setLoading(true)
  const result =await handleOrderStatus(id,status)
  console.log("the result is ===>",result)
}
const handleCloseDeal= async(id)=>{
  setLoading(true)
  const result =await handleDealStatus(id,"closed")
  console.log("the result is ===>",result)
}

const handleEmailModel=(forename,email)=>{
  formik.setFieldValue('member',forename);
  formik.setFieldValue('email',email);

  setEmailModal(true)
  setuserData(forename)

}
  return (

    <div>
     {!loading ? (
    <div className='text-dark pb-4'>
        <div className="container">
            <div className="row">
                <h3 className="text-center pt-4">Orders</h3>
                <div className="col-12">
                <table class="table">
  <thead class="table-dark">
    <tr>
  <td>Deal</td>
  <td>Type</td>
  <td>Counter Party User</td>
  <td>Delivery Method</td>
  <td>Upload Cards</td>
  <td>Confirmation</td>
  <td>Status</td>
  <td>Contact User</td>

  <td>Repoart Issue</td>
  
 

    </tr>
  </thead>
  <tbody>
  {orderItems && orderItems?.map((item,index)=>(
  <>
  {item.type ==="swap" &&(
  <tr key={index}>
  <td>{item.deal_no}</td>
  <td>{item.type}</td>
  <td>{item.order_swap_detail.user.forename}</td>
  <td>Delivery Method</td>
  <td>
    <select className='form-control' value={item.upload_card}
    onChange={(e)=>handleUploadStatus(item?.order_swap_detail?.order_id,e.target.value)}
    >
      
      <option value="pending">Pending</option>
      <option value="uploaded">Uploaded</option>
    </select>
  </td>
  <td className='text-center'>
    {item?.status !=="closed" ?
    <button 
   type='button'
  className='btn btn-primary'
  onClick={()=>handleCloseDeal(item?.order_swap_detail?.order_id)}
  >Close Deal </button>
  
  :
  <button 
  type='button'
 className='btn btn-danger'

 >Closed </button>

    }
  
  </td>
  <td>{item?.status}</td>
  <td>
  <button 
   type='button'
  className='btn btn-primary'
  onClick={()=>{
    handleEmailModel(item.order_swap_detail.user.forename,item.order_swap_detail.user.email)
    
  }}
  >Send Email </button>
  </td>

  <td>Repoart Issue</td>
  
 

    </tr>
    )}
  {item.type ==="sale" &&(
  <tr key={index}>
  <td>{item.deal_no}</td>
  <td>{item.type}</td>
  <td>{item?.order_detail[0]?.user.forename}</td>
  <td>Delivery Method</td>
  <td>
    <select className='form-control' value={item.upload_card}
    onChange={(e)=>handleUploadStatus(item?.customer_order_detail?.order_id,e.target.value)}
    >
      
      <option value="pending">Pending</option>
      <option value="uploaded">Uploaded</option>
    </select>
  </td>
  <td className='text-center'>
    {item?.status !=="closed" ?
    <button 
   type='button'
  className='btn btn-primary'
  onClick={()=>handleCloseDeal(item?.customer_order_detail?.order_id)}
  >Close Deal </button>
  
  :
  <button 
  type='button'
 className='btn btn-danger'

 >Closed </button>

    }
  
  </td>
  <td>{item?.status}</td>
  {console.log("the item is,",item)}
  <td>
  <button 
   type='button'
  className='btn btn-primary'
  onClick={()=>{
    handleEmailModel(item?.order_detail[0]?.user.forename,item?.order_detail[0]?.user.email)
    
  }}
  >Send Email </button>
  </td>

  <td>Repoart Issue</td>
  
 

    </tr>
    )}
    </>
    ))}
{/*   
  Deal		Type		Counterparty User		Delivery Method		Upload Cards		Confirmation		Status		Contact User		Report Issue	 */}
  </tbody>
</table>
                </div>
            </div>
        </div>

            {/* model start */}
      <Modal
        show={emailModal}
        onHide={() => setEmailModal(false)}
        animation={false}
        dialogClassName="bg-white w-100"
      >
        <Modal.Header className="border-bottom-0 pb-0" closeButton>
          <Modal.Title as="h4" className="w-100 text-center p-0 ">
            Send Email to {userData}
          </Modal.Title>
        </Modal.Header>
      
        <Modal.Body className="text-dark">
       <div>
        <div className="row">
      
       <form onSubmit={formik.handleSubmit} class="col-md-12">
       <div class="field-set">
                  <TextField
                   
                    label="Cards Got"
                    name="card_got"
                    placeholder="Cards Got"
                    formik={formik}
                   
                  />
                </div>
       <div class="field-set">
                  <TextField
                   
                    label="Cards Need"
                    name="card_needed"
                    placeholder="Cards Need"
                    formik={formik}
                   
                  />
                </div>
                <div class="field-set">
                  <TextField
                    label="Message"
                    name="message"
                    multi={true}
                    placeholder="Enter Message"
                    formik={formik}
                  />
                </div>
                <div class="field-set">
                  <button className="btn btn-primary" type="submit">Send Message</button>
                </div>
              </form>
       </div>
       </div>
        </Modal.Body>
      </Modal>
      {/* model end */}
    </div>
    ) : (
      <div className="vh-50 d-flex justify-content-center align-items-center">
        <ClipLoader loading={loading} color="#333" size={50} />
      </div>
    )}
  </div>
);
}
